<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'Chart',
  extends: Bar,
  props: ['data', 'options'],
  components: {
    Bar
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
  watch: {
    data () {
      this.renderChart(this.data, this.options)
    }
  }
}
</script>
