<template>
  <div class="dashboard">
    <div class="dashboard__left">
      <img class="logo" src="/static/images/Logo.AK.Werkstatt.png" alt="Logo" title="Logo">
    </div>
    <div v-if="showTeam" class="dashboard__right">
      <span>Gruppe {{ team.group }}</span>
      <h3>{{ team.name }}</h3>
      <span class="cryptos">
        {{ team.kryptos }} Kryptos
        <img src="/static/images/kryptos.01.png" alt="">
      </span>
    </div>
    <span v-for="(member, index) in members"
      v-if="member && member.avatar"
      :key="index"
      class="avatar">
      <img :src="`/static/images/avatare/avatar${member.avatar}.png`" alt="Avatar">
      <div class="avatar__popup">
        <span class="avatar__name">
          {{ member.name }}
        </span>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  props: ['team'],
  computed: {
    members () {
      return this.team.members
    },
    showTeam () {
      return this.team.name || this.members.reduce((prev, member) => prev || member.name || member.avatar, false)
    }
  }
}
</script>

<style lang="scss">
.dashboard {
  z-index: 100;
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  h3 {
    margin: 0;
    line-height: 20px;
    margin-bottom: 5px;
    min-width: 200px;
  }
  .cryptos {
    position: relative;
    img {
      position: absolute;
      height: 70px;
      right: -110px;
      top: 0;
    }
  }
  .avatar {
    position: absolute;
    height: 70px;
    cursor: pointer;
    &:hover img {
      animation: tada1 1s both;
    }
    &__popup {
      cursor: auto;
      opacity: 0;
      transition: 0.3s;
      background-color: white;
      box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
      padding: 3px 15px;
      position: absolute;
      right: 85px;
      top: 40%;
      transform: translateY(-50%);
      visibility: hidden;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent white;
        transition: 0.3s;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
      }
    }
    &:hover .avatar__popup {
      opacity: 1;
      visibility: visible;
    }
    img {
      height: 100%;
      display: block;
    }
    &:nth-of-type(1) {
      right: -50px;
      top: 5px;
    }
    &:nth-of-type(2) {
      right: -130px;
      top: 50px;
    }
    &:nth-of-type(3) {
      right: -100px;
      top: 125px;
    }
  }
}

@keyframes tada1 {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  10%, 20% {
  -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
}
</style>
