<template>
  <div class="scene scene--focused">
    <div class="scene__island-wrapper">
      <template v-for="i in islandCount">
        <Component v-if="slideTypes.includes(i - 1)"
          :is="`Island${i}`"
          :key="i"
          :class="{
            // activeTheme can be 0 so we have to explecitly compare for false
            'island--active': activeTheme !== false && activeTheme === i - 1,
            'island--hidden': activeTheme !== false && activeTheme !== i - 1
          }"
        />
        <div :key="i" v-else class="island" />
      </template>
    </div>
    <div class="scene__sky-wrapper">
      <Sky class="sky" v-for="n in 5" :key="n" />
    </div>
  </div>
</template>

<script>
import * as islands from './Islands'
import Sky from './Sky.vue'

export default {
  name: 'Scene',
  data: () => ({
    islandCount: Object.keys(islands).length
  }),
  props: ['activeTheme', 'slideTypes'],
  components: {
    Sky,
    ...islands
  }
}
</script>

<style lang="scss">
.scene {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  &__island-wrapper {
    height: 100%;
    width: 1300px;
    margin: auto;
    position: relative;
  }

  &__sky-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
}

.sky {
  &:nth-of-type(1) {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 70%;
    z-index: 15;
  }
  &:nth-of-type(2) {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 10%;
    z-index: 55;
  }

  &:nth-of-type(3) {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 25%;
    z-index: 45;
  }

  &:nth-of-type(4) {
    position: absolute;
    width: 100%;
    height: 25vh;
    bottom: 35%;
    z-index: 35;
  }

  &:nth-of-type(5) {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 45%;
    z-index: 25;
  }
}

.island {
  background-position: center;
  background-size: cover;
  position: absolute;
  transition: 1.5s;
  height: 1300px;
  width: 1300px;
  transform-origin: bottom;
  &:nth-of-type(1) {
    left: 55%;
    bottom: 0%;
    transform: translateX(-50%) scale(0.52);
    z-index: 50;
    will-change: transform, opacity, zoom;
    &.island--hidden {
      transform: scale(0.7) translateX(-85%) translateY(100%);
      opacity: 0;
    }
  }
  &:nth-of-type(2) {
    left: 22%;
    bottom: 20%;
    transform: translateX(-50%) scale(0.4);
    z-index: 40;
    will-change: transform, opacity, zoom;
    &.island--hidden {
      transform: scale(0.7) translateX(-200%) translateY(100px);
      opacity: 0;
    }
  }
  &:nth-of-type(3) {
    left: 25%;
    bottom: 34%;
    transform: translateX(-50%) scale(0.36);
    z-index: 30;
    will-change: transform, opacity, zoom;
    &.island--hidden {
      transform: scale(0.7) translateX(-200%) translateY(-200px);
      opacity: 0;
    }
  }
  &:nth-of-type(4) {
    left: 51%;
    bottom: 42%;
    transform: translateX(-50%) scale(0.3);
    z-index: 20;
    will-change: transform, opacity, zoom;
    &.island--hidden {
      transform: scale(0.7) translateY(-200%);
      opacity: 0;
    }
  }
  &:nth-of-type(5) {
    left: 77%;
    bottom: 42%;
    transform: translateX(-50%) scale(0.35);
    z-index: 30;
    &.island--hidden {
      transform: scale(0.7) translateX(200%) translateY(-200px);
      opacity: 0;
    }
  }
  &:nth-of-type(6) {
    left: 93%;
    bottom: 29%;
    transform: translateX(-50%) scale(0.4);
    z-index: 40;
    will-change: transform, opacity, zoom;
    &.island--hidden {
      transform: scale(0.7) translateX(200%) translateY(200px);
      opacity: 0;
    }
  }
  &:nth-of-type(7) {
    left: 83%;
    bottom: 17%;
    transform: translateX(-50%) scale(0.4);
    z-index: 40;
    will-change: transform, opacity, zoom;
    &.island--hidden {
      transform: scale(0.7) translateX(200%) translateY(500px);
      opacity: 0;
    }
  }
  &--active {
    z-index: 100 !important;
    transform: translateX(-50%) scale(1.1) !important;
    left: 50% !important;
    opacity: 1 !important;
    &.island--1 {
      bottom: -840px!important;
    }
    &.island--2 {
      bottom: -770px!important;
    }
    &.island--3, &.island--5, &.island--6, &.island--4 {
      bottom: -790px!important;
    }
    &.island--5 {
      bottom: -770px!important;
    }
    &.island--7 {
      bottom: -800px !important;
    }
  }
  canvas {
    &:hover {
      animation: tada 1s both;
    }
  }
}
.buttontest {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 100;
}

@keyframes tada {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  10%, 20% {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
}
</style>
