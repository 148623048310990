<template>
<div class="countdown">
  <div class="countdown__done" ref="done" :style="doneStyle">
    <span :class="{ 'right' : setTimePosition}">{{ timeLeft }}</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'Countdown',
  props: ['time'],
  data () {
    return {
      running: false,
      timeLeft: this.time
    }
  },
  methods: {
    pause () {
      this.running = false
      clearInterval(this.intervalID)
    },
    unpause () {
      this.running = true
      this.intervalID = setInterval(() => {
        if (this.timeLeft === 0) {
          this.pause()
        } else {
          this.timeLeft--
        }
      }, 1000)
    },
    reset () {
      this.pause()
      this.timeLeft = this.time
      // reseting animation, touching offsetHeight triggers a reflow
      this.$refs.done.style.animation = 'none'
      this.$refs.done.offsetHeight // eslint-disable-line
      this.$refs.done.style.animation = ''
    }
  },
  computed: {
    doneStyle () {
      return {
        'animation': `expand ${this.time}s linear ${this.running ? 'running' : 'paused'} forwards`
      }
    },
    setTimePosition () {
      return !((1 - this.timeLeft / this.time) * 100 > 93)
    }
  }
}
</script>

<style lang="scss">
.countdown {
  height: 13px;
  width: 100%;
  background-color: #95c0e2;
  position: relative;
  left: 0;
  right: 0;
  z-index: 10;
  overflow: hidden;
  &__done {
    height: 100%;
    width: 100%;
    background-color: #559cd2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translate(-100%);
    will-change: transform;
    span {
      padding-right: 10px;
      font-size: 0.7rem;
      color: white;
      &.right {
        position: absolute;
        right: -40px;
        top: -8px;
      }
    }
  }
}

@keyframes expand {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}
</style>
