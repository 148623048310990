<template>
  <PresentationWrapper>
    <div class="slide__body slide__body--enumerations">
      <div class="enumerations">
        <div class="enumerations__header">
          <h1>{{ data.name }}</h1>
        </div>
        <div class="enumerations__body">
          <transition-group name="list" tag="ul" class="enumerations__list">
            <li v-for="(point, index) in list" :key="index" v-html="point"></li>
          </transition-group>
          <div class="image" v-if="attributes.image !== null">
            <img :src="attributes.image" alt />
          </div>
        </div>
      </div>
    </div>
  </PresentationWrapper>
</template>

<script>
import { slideMixin } from './slide-mixin'
import { presentationMixin } from './presentation'

export default {
  name: 'Enumerations',
  mixins: [slideMixin, presentationMixin],
  computed: {
    list () {
      return this.context === 'trainer'
        ? this.attributes.list
        : this.attributes.list.slice(0, this.attributes.currentSlidePart)
    }
  }
}
</script>

<style lang="scss">
.enumerations {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__list {
    line-height: normal;
    height: 100%;
    font-size: 2.2rem;
    flex: 1;
  }
  .list-enter-active,
  .list-leave-active {
    transition: all 1s;
  }
  .list-enter,
  .list-leave-to {
    opacity: 0;
  }

  .image {
    flex: 1;
    img {
      width: 100%;
    }
  }
}
</style>
