<template>
    <div class="slide__body slide__body--text">
      <div class="text">
        <div class="text__header">
          <h1>{{ data.name }}</h1>
          <p v-html="attributes.text"></p>
        </div>
        <div class="text__body">
          <textarea v-model="message"></textarea>
        </div>
      </div>
    </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'FeedbackSlide',
  mixins: [slideMixin],
  methods: {
    initState: () => ({
      message: ''
    }),
    getAnswer () {
      return this.message
    }
  }
}
</script>

<style lang="scss">
.text {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 80%;
    margin: 0 auto;
    padding: 30px 0;
  }
  textarea {
    height: 300px;
    width: 100%;
    font-size: 1.3rem;
    padding: 10px;
  }
}
</style>
