<template>
  <div class="slide__body slide__body--transition">
    <div class="transition">
      <img :src="`/static/images/island${island + 1}.png`" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transition',
  props: ['island']
}
</script>

<style lang="scss">
.slide__body--transition {
  margin: 0 auto;
  margin-top: 45px;
  height: 400px;
  width: 300px;
  max-width: 300px;
  background: linear-gradient(to bottom, #ffffff, #559cd2);
  border: 1px solid #e2e2e2;
}
.transition {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
  }
}
</style>
