<template>
  <div class="main-wrapper">
    <transition name="white-overlay">
      <div class="white-overlay" v-if="transitioning"/>
    </transition>
    <Preview :team="team" :activeTheme="activeTheme" :slideTypes="slideTypes">
      <div class="token-preview" v-if="context === 'presentation' && currentSlideIndex === 0">
        <span>{{ studentURL }}</span>
        <span>{{ metadata.token }}</span>
      </div>
    </Preview>
    <div class="student-wrapper" v-if="showSlide && state.slide">
      <DynamicSlide
        ref="dynamicSlide"
        :key="state.slide.id"
        :context="context"
        :data="state.slide"
        :metadata="_metadata"
        :state="state.slideState"
        :solution="solution"
        @team-update="setTeam"
        @state="handleSlideStateChange"
        class="slide--student"
      >
        <Results v-if="showResults" :solution="solution" :slide="state.slide"/>
        <div v-if="showResults" class="quiz-disable"/>
        <SlidePaused v-if="showSlidePaused"/>
      </DynamicSlide>
    </div>
  </div>
</template>

<script>
import { DynamicSlide } from '@/common'
import { Preview } from './Preview'
import { Results } from './Results'
import { SlidePaused } from './SlidePaused'

import {
  SLIDE_INITIAL,
  SLIDE_INTERACTIVE,
  SLIDE_PAUSED,
  SLIDE_RESULT
} from '../../slide-states'

const noopDefault = () => ({
  type: Function,
  default: () => {}
})

export default {
  name: 'Quiz',
  props: {
    context: {
      type: String,
      default: 'student'
    },
    metadata: Object,
    state: Object,
    team: {
      type: Object,
      default: () => ({
        name: '',
        members: []
      })
    },
    setTeam: noopDefault(),
    handleSlideStateChange: noopDefault(),
    handleAnswer: noopDefault()
  },
  data: () => ({
    transitioning: true
  }),
  components: {
    DynamicSlide,
    Preview,
    Results,
    SlidePaused
  },
  mounted () {
    this.$nextTick(() => {
      this.transitioning = false
    })
  },
  computed: {
    _metadata () {
      return {
        currentSlideIndex: this.currentSlideIndex,
        totalSlides: this.totalSlides,
        users: this.members
      }
    },
    showSlide () {
      return (
        (this.context === 'presentation' &&
          this.state.slide.type !== 'slide_transition') ||
        [SLIDE_INTERACTIVE, SLIDE_RESULT, SLIDE_PAUSED].includes(
          this.state.type
        )
      )
    },
    showResults () {
      return this.state.type === SLIDE_RESULT
    },
    showSlideNotInteractive () {
      return this.state.type === SLIDE_INITIAL
    },
    showSlidePaused () {
      return this.state.type === SLIDE_PAUSED
    },
    totalSlides () {
      return this.state.totalSlides
    },
    currentSlideIndex () {
      return this.state.currentSlideIndex
    },
    activeTheme () {
      const state = this.state
      return (
        !!state.slide &&
        state.slide.type !== 'slide_transition' &&
        state.slide.topic !== -1 &&
        state.slide.topic
      )
    },
    solution () {
      return this.state.solution
    },
    slideTypes () {
      return this.state.slideTypes
    },
    members () {
      return this.team.members
    },
    type () {
      return this.state.type
    },
    studentURL () {
      return window.location.host
    }
  },
  watch: {
    type (newState, oldState) {
      const isTransition = (from, to) => from === oldState && to === newState

      if (isTransition(SLIDE_INTERACTIVE, SLIDE_RESULT)) {
        const answer = this.$refs.dynamicSlide.getAnswer()
        this.$refs.dynamicSlide.pauseSlide()
        this.handleAnswer(answer)
      }

      if (isTransition(SLIDE_INITIAL, SLIDE_INTERACTIVE)) {
        this.$nextTick(() => {
          this.$refs.dynamicSlide.resetSlide()
          this.$refs.dynamicSlide.unpauseSlide()
        })
      }

      if (isTransition(SLIDE_PAUSED, SLIDE_INTERACTIVE)) {
        this.$nextTick(() => {
          this.$refs.dynamicSlide.unpauseSlide()
        })
      }

      if (isTransition(SLIDE_INTERACTIVE, SLIDE_PAUSED)) {
        this.$refs.dynamicSlide.pauseSlide()
      }
    }
  }
}
</script>

<style lang="scss">
.main-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-height: 985px) {
    transform: scale(0.7);
    transform-origin: 0 0;
    width: 142.857143% !important;
    height: 142.857143% !important;
  }
}
.student-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.white-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #ffffff;
}
.token-preview {
  position: absolute;
  z-index: 1000;
  background-color: hsla(0, 0%, 100%, 0.8);
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 34px;
  padding: 1.4rem;
  width: 800px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.quiz-disable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.white-overlay-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.white-overlay-leave-to {
  opacity: 0;
}
.slide--student {
  margin-top: 5%;
  z-index: 100;
}
.delay-enter-active {
  transition-delay: 1.5s;
  opacity: 0;
}
</style>
