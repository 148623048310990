<template>
  <div class="slide__body slide__body--multiple-choice">
    <div class="multiple-choice">
      <div class="multiple-choice__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="multiple-choice__body">
        <ul class="multiple-choice__content">
          <li v-for="(element, index) in attributes.answers" :key="element">
            <label class="checkbox__container" :class="getSolutionClassList(index)">
              <input class="checkbox__field" type="checkbox" :id="index" v-model="checked[index]">
              <span>{{element}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div v-if="attributes.showCorrectAnswers">
        <p>Richtige Antworten: {{attributes.correctAnswers.length}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'MultipleChoice',
  mixins: [slideMixin],
  methods: {
    initState () {
      return {
        checked: Array(this.data.attributes.answers.length).fill(false)
      }
    },
    getAnswer () {
      return this.checked
        .map((v, i) => v && i)
        .filter(v => Number.isInteger(v))
    },
    getSolutionClassList (index) {
      if (!this.solution) return []
      const classList = ['solution']
      if (this.attributes.correctAnswers.includes(index)) {
        classList.push('solution--show')
        if (this.getAnswer().includes(index)) {
          classList.push('solution--right')
        }
      }
      return classList
    }
  }
}
</script>

<style lang="scss">
.multiple-choice {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__content {
    list-style: none;
    padding: 0px;
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 46%;
      margin-bottom: 15px;
    }
  }
  .checkbox {
    &__container {
      padding: 0;
      margin: 0;
      width: 100%;
      span {
        background-color: #bbddf6;
        box-shadow: 0 6px #91c2e6;
        padding: 10px 25px;
        margin: 0;
        border-radius: 12px;
        width: 100%;
        text-align: center;
        line-height: 22px;
        min-height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        &:active {
          box-shadow: 0 2px #91c2e6;
          transform: translateY(4px);
        }
      }
    }
    &__field:checked + span {
      box-shadow: 0 6px #c99034;
      background: linear-gradient(to right, #ebc65a 0%, #fab545 100%);
      &:active {
        box-shadow: 0 2px #da9422;
        transform: translateY(4px);
      }
    }
  }
}

.solution {
  visibility: hidden;
  &--show {
    visibility: visible;
  }
  &--right {
    .checkbox__field:checked + span {
      background: #389838;
      box-shadow: 0 6px #166f16;
      color: white
    }
  }
}
</style>
