<template>
  <div class="island island--2">
    <div id="animation_container_73F3CEC5BE084F60859EAC554BB43196" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_73F3CEC5BE084F60859EAC554BB43196" style="position: absolute; display: block; background-color:rgba(255, 255, 255, 0.00); z-index: 41; top: 24%; right: 11.5%;"></canvas>
      <div id="dom_overlay_container_73F3CEC5BE084F60859EAC554BB43196" style="pointer-events:none; overflow:hidden; position: absolute; left: 0px; top: 0px; display: block;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island2',
  mounted () {
    Animation.init('73F3CEC5BE084F60859EAC554BB43196')
  }
}
</script>

<style lang="scss">
  .island--2 {
    background-image: url('/public/static/images/island2.png');
  }
</style>
