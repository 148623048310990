import cjs from 'createjs'
/* eslint-disable */

export function createAnimation () {
var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"hallo.xs_atlas_", frames: [[2184,0,1090,807],[1092,809,1090,807],[2184,809,1090,807],[0,0,1090,807],[1092,0,1090,807],[0,1618,1090,807],[0,809,1090,807]]}
];


// symbols:



(lib.avatarkarte01 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.avatarkarte02 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.avatarkarte03 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.avatarkarte04 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.avatarkarte05 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(4);
}).prototype = p = new cjs.Sprite();



(lib.avatarkarte06 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(5);
}).prototype = p = new cjs.Sprite();



(lib.avatarkarte07 = function() {
	this.spriteSheet = ss["hallo.xs_atlas_"];
	this.gotoAndStop(6);
}).prototype = p = new cjs.Sprite();



(lib.avatar06 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarkarte06();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1090,807);


(lib.avatar05 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarkarte05();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1090,807);


(lib.avatar04 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarkarte04();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1090,807);


(lib.avatar03 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarkarte03();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1090,807);


(lib.avatar02 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarkarte02();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1090,807);


(lib.avatar01 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarkarte01();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1090,807);


// stage content:
(lib.Animation = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_7
	this.instance = new lib.avatarkarte07();
	this.instance.parent = this;
	this.instance.setTransform(0,0,0.171,0.171);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(63).to({_off:false},0).to({_off:true},51).wait(54));

	// Ebene_6
	this.instance_1 = new lib.avatar06("synched",0);
	this.instance_1.parent = this;
	this.instance_1.setTransform(93.2,69,0.171,0.171,0,0,0,545.1,403.6);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(60).to({_off:false},0).to({_off:true},3).wait(51).to({_off:false},0).to({_off:true},4).wait(50));

	// Ebene_5
	this.instance_2 = new lib.avatar05("synched",0);
	this.instance_2.parent = this;
	this.instance_2.setTransform(93.2,69,0.171,0.171,0,0,0,545.1,403.6);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(57).to({_off:false},0).to({_off:true},3).wait(58).to({_off:false},0).to({_off:true},4).wait(46));

	// Ebene_4
	this.instance_3 = new lib.avatar04("synched",0);
	this.instance_3.parent = this;
	this.instance_3.setTransform(93.2,69,0.171,0.171,0,0,0,545.1,403.6);
	this.instance_3._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(54).to({_off:false},0).to({_off:true},3).wait(65).to({_off:false},0).to({_off:true},4).wait(42));

	// Ebene_3
	this.instance_4 = new lib.avatar03("synched",0);
	this.instance_4.parent = this;
	this.instance_4.setTransform(93.2,69,0.171,0.171,0,0,0,545.1,403.6);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(51).to({_off:false},0).to({_off:true},3).wait(72).to({_off:false},0).to({_off:true},4).wait(38));

	// Ebene_2
	this.instance_5 = new lib.avatar02("synched",0);
	this.instance_5.parent = this;
	this.instance_5.setTransform(93.2,69,0.171,0.171,0,0,0,545.1,403.6);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(48).to({_off:false},0).to({_off:true},3).wait(79).to({_off:false},0).to({_off:true},4).wait(34));

	// Ebene_1
	this.instance_6 = new lib.avatar01("synched",0);
	this.instance_6.parent = this;
	this.instance_6.setTransform(93.2,69,0.171,0.171,0,0,0,545.1,403.6);

	this.timeline.addTween(cjs.Tween.get(this.instance_6).to({_off:true},48).wait(86).to({_off:false},0).wait(33).to({startPosition:0},0).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(93.5,69,186.4,138);
// library properties:
lib.properties = {
	id: '2BC1D9F0641643E299E2501102553595',
	width: 157,
	height: 138,
	fps: 24,
	color: "#FFFFFF",
	opacity: 0.00,
	manifest: [
		{src:"/static/images/hallo.xs_atlas_.png", id:"hallo.xs_atlas_"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	cjs.Stage.call(this, canvas);
}).prototype = p = new cjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

return {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};
}
