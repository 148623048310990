import { default as Cloze } from './Cloze.vue'
import { default as Cryptochallenge } from './Cryptochallenge.vue'
import { default as DragDropContainer } from './DragDropContainer'
import { default as DragDropPairing } from './DragDropPairing.vue'
import { default as Enumerations } from './Enumerations.vue'
import { default as Estimation } from './Estimation.vue'
import { default as Hangman } from './Hangman.vue'
import { default as Login } from './Login'
import { default as MapTyrol } from './MapTyrol.vue'
import { default as Memory } from './Memory.vue'
import { default as MultipleChoice } from './MultipleChoice.vue'
import { default as Password } from './Password.vue'
import { default as Score } from './Score.vue'
import { default as Slider } from './Slider.vue'
import { default as SocialMedia } from './SocialMedia.vue'
import { default as Survey } from './Survey.vue'
import { default as SurveyResult } from './SurveyResult.vue'
import { default as Feedback } from './Feedback.vue'
import { default as TextImage } from './TextImage.vue'
import { default as Transition } from './Transition.vue'

export { default as SlideBase } from './SlideBase.vue'

export const slides = {
  Cloze,
  Cryptochallenge,
  DragDropContainer,
  DragDropPairing,
  Enumerations,
  Estimation,
  Hangman,
  Login,
  MapTyrol,
  Memory,
  MultipleChoice,
  Password,
  Score,
  Slider,
  SocialMedia,
  Survey,
  SurveyResult,
  Feedback,
  TextImage,
  Transition
}
