<template>
  <div class="slide__body slide__body--memory">
    <div class="memory">
      <div class="memory__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="memory__body" :class="`memory--${cards.length}`">
        <div class="memory-card" v-for="(card, index) in cards"
          :key="index" @click="flippCard(card)"
          :class="{ 'match': card.matched, 'flipped': card.flipped || solution, 'disabled': card.disabled }"
          :style="{ 'border-color': solution && colors[card.id] }"
        >
          <div class="memory-card__front">
            <img v-if="card.image" :src="card.image" />
            <span v-if="card.text">{{ card.text }}</span>
          </div>
          <div class="memory-card__back" />
        </div>
      </div>
      Versuche: {{tries}}
    </div>
  </div>
</template>

<script>
import { shuffle } from 'lodash'
import { slideMixin } from './slide-mixin'

const colors = [
  '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4',
  '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff',
  '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1',
  '#000075', '#808080'
]

const createCards = memoryPairs => shuffle(
  memoryPairs.reduce((arr, pair, index) => {
    pair.forEach(value =>
      arr.push({
        id: index,
        [value.startsWith('http') ? 'image' : 'text']: value,
        flipped: false,
        matched: false,
        disabled: false
      })
    )
    return arr
  }, [])
)

export default {
  mixins: [slideMixin],
  created () {
    this.colors = colors
  },
  methods: {
    initState () {
      return {
        cards: createCards(this.data.attributes.memoryPairs),
        tries: 0,
        uncovered: 0,
        openedCards: []
      }
    },
    flippCard (card) {
      card.flipped = true
      card.disabled = true
      this.openedCards.push(card)
      if (this.openedCards.length === 2) {
        this.tries++
        this.cards.forEach(card => {
          card.disabled = true
        })
        const [card1, card2] = this.openedCards
        setTimeout(() => {
          if (card1.id === card2.id) {
            card1.matched = card2.matched = card1.disabled = card2.disabled = true
            this.uncovered++
          } else {
            card1.flipped = card2.flipped = card1.disabled = card2.disabled = false
          }
          this.openedCards = []
          this.cards.forEach(card => {
            card.disabled = false
          })
        }, 1100)
      }
    },
    getAnswer () {
      return {
        uncovered: this.uncovered,
        tries: this.tries
      }
    }
  }
}
</script>

<style lang="scss">
.memory {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 0 0 0;
  }
  input {
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    font-size: 1rem;
    outline: 0;
  }
  &-card {
    flex-basis: 130px;
    height: 130px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 1px 3px 1px rgba(60, 64, 67, 0.149);
    margin: 15px;
    transition: box-shadow 0.3s, transform 0.6s, border 0.3s;
    transform-style: preserve-3d;
    cursor: pointer;
    perspective: 1000px;
    position: relative;
    will-change: transform;
    border: 2px solid transparent;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.552),
        0 1px 3px 1px rgba(60, 64, 67, 0.31);
    }
    &.flipped {
      transform: rotateY(180deg);
      // Ie Fix
      // Pls remove this as soon as possible
      //
      //
      .memory-card__front {
        backface-visibility: visible;
      }
      .memory-card__back {
        backface-visibility: visible;
        background-image: none;
      }
      //
      //
      // PLSSSSSSSS REMOVE ME
    }
    &__front {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: rotateY(180deg);
      z-index: 2;
      padding: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
      span {
        word-wrap: break-word;
        max-width: 100%;
        text-align: center;
        font-size: 1em;
        line-height: 25px;
      }
    }
    &__back {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: rotateY(0deg);
      background-image: url('/public/static/images/memory.bg.01.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 10px;
    }
  }
  &--4 {
    .memory-card {
      flex-basis: 230px;
      height: 230px;
    }
  }
  &--6 {
    padding: 0 300px;
    .memory-card {
      flex-basis: 180px;
      height: 180px;
    }
  }
  &--8 {
    padding: 0 200px;
    .memory-card {
      flex-basis: 180px;
      height: 180px;
    }
  }
  &--10 {
    padding: 0 90px;
    .memory-card {
      flex-basis: 180px;
      height: 180px;
    }
  }
  &--12 {
    padding: 0 280px;
    .memory-card {
      flex-basis: 140px;
      height: 140px;
    }
  }
  &--14 {
    padding: 0 20px;
    .memory-card {
      flex-basis: 140px;
      height: 140px;
    }
  }
}

.disabled, .match {
  pointer-events: none;
  cursor: auto;
}
.match {
  border-color: #ad1;
}
</style>
