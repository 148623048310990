<template>
  <div class="avatar-selector">
    <div @click="selectAvatar(null)" class="avatar-selector__close">
      <i class="fas fa-times"></i>
    </div>
    <div class="avatar-selector__wrapper">
      <img v-for="id in avatarIds"
        @click="selectAvatar(id)"
        :src="`/static/images/avatare/avatar${id}.png`"
        :key="id"
        class="avatar-selector__item"
        alt="Avatar">
    </div>
  </div>
</template>

<script>
import { range } from 'lodash'

const avatarIds = range(1, 31)

export default {
  data: () => ({
    avatarIds
  }),
  methods: {
    selectAvatar (id) {
      this.$emit('avatar-selection', id)
    }
  }
}
</script>

<style lang="scss">
.avatar-selector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 80%;
    width: 80%;
    padding: 15px;
    position: absolute;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    overflow: scroll;
  }
  &__item {
    width: 16%;
    height: auto;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      border-color: #1f4465;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px;
    color: white;
    cursor: pointer;
  }
}
</style>
