<template>
  <component
    v-if="data.type === 'slide_transition'"
    ref="slide"
    :is="slideType"
    :slideId="slideId"
    :data="data"
    :island="data.topic"
  ></component>
  <slide-base
    v-else
    ref="slideBase"
    :data="data"
    :metadata="metadata"
    :context="context"
  >
    <component
      ref="slide"
      :is="slideType"
      :slideId="slideId"
      :data="data"
      :state="state"
      :context="context"
      :metadata="metadata"
      :solution="solution"
    ></component>
    <slot>
      <!-- inject overlays for student slides -->
    </slot>
  </slide-base>
</template>

<script>
import { camelCase, upperFirst } from 'lodash'
import { slides, SlideBase } from './slides'

export default {
  props: {
    context: {
      type: String,
      default: 'default'
    },
    data: Object,
    metadata: Object,
    state: Object,
    solution: [Boolean, Object]
  },
  components: {
    ...slides,
    SlideBase
  },
  methods: {
    getAnswer () {
      // we have to serialize/deserialize the answer to not submit an
      // vuex.observable object
      const answer = this.$refs.slide.getAnswer()
      return JSON.parse(JSON.stringify(answer))
    },
    unpauseSlide () {
      this.$refs.slideBase.$refs.countdown.unpause()
    },
    pauseSlide () {
      this.$refs.slideBase.$refs.countdown.pause()
    },
    resetSlide () {
      this.$refs.slideBase.$refs.countdown.reset()
      this.$refs.slide.resetState()
    }
  },
  computed: {
    slideType () {
      return upperFirst(camelCase(this.data.type.replace('slide_', '')))
    },
    slideId () {
      return this.data.id
    }
  }
}
</script>
