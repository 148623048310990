<template>
  <div class="slide__body slide__body--estimation">
    <div class="estimation">
      <div class="estimation__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="estimation__body">
        <input v-if="!solution" ref="input" v-model="value" type="text" class="estimation-input" step="0.5" />
        <span v-else class="estimation-input" :class="{ 'estimation-input--success': isSuccess }">{{ value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'Estimations',
  mixins: [slideMixin],
  methods: {
    initState: () => ({
      value: ''
    }),
    getAnswer () {
      return this.value
    }
  },
  computed: {
    isSuccess () {
      const { value } = this
      const { from, to } = this.attributes.intervals[1]
      return from < value && value < to
    }
  },
  mounted () {
    if (this.context === 'student' && this.$refs.input) {
      this.$refs.input.focus()
    }
  },
  watch: {
    value (newValue, oldValue) {
      // validate for maximal two decimals
      if (!/^(\d*)(\.?\d{0,2})$/.test(newValue)) {
        this.value = oldValue
      }
    }
  }
}
</script>

<style lang="scss">
.estimation {
  width: 100%;

  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.estimation-input {
  font-weight: 700;
  display: block;
  font-size: 4rem;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #559cd2;
  height: 81px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 30%;
  text-align: center;
  line-height: normal;
  &--success {
    border-bottom: 2px solid #389838;
    color: #389838;
  }
  &:focus {
    outline: none;
  }
}
</style>
