<template>
  <div class="slide">
    <div class="slide__header" v-if="context !== 'student' && context !== 'presentation'">
      <div class="slide__title">
        <span>
          <b>{{ data.slideNumber }}</b>
          von {{ metadata.totalSlides }}
        </span>
      </div>
      <span></span>
    </div>
    <slot></slot>
    <div class="slide__footer">
      <span class="slide__title" v-if="data.type != 'slide_estimation'">
        <span v-if="data.win != 0">
          <b>{{ data.win }} Kryptos</b> für die richtige Lösung
        </span>
      </span>
      <span class="slide__title" v-else>
        <span>
          bis zu <b>{{ data.attributes.intervals[0].win }} Kryptos</b> für die richtige Lösung
        </span>
      </span>
      <Countdown ref="countdown" :time="data.time"/>
    </div>
  </div>
</template>

<script>
import Countdown from '../Countdown.vue'

export default {
  name: 'SlideBase',
  components: {
    Countdown
  },
  props: {
    context: {
      type: String,
      default: 'default'
    },
    data: {
      type: Object,
      default: () => ({})
    },
    metadata: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
.slide {
  background-color: rgba(248, 255, 255, 0.95);
  height: 800px;
  width: 1300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    font-size: 1.3rem;
  }
  &__body {
    padding: 30px;
    position: relative;
    background: transparent;
    flex: 1;
    font-size: 1.3rem;
    h1 {
      margin: 0;
      line-height: 50px;
      font-size: 2.5rem;
    }
    h2 {
      font-size: 1.6rem;
    }
    h4 {
      font-size: 1.2rem;
    }
  }
  &__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  &__title {
    float: right;
    span {
      padding: 15px 30px;
      display: block;
    }
  }
}
</style>
