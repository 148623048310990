<script>
import { random } from 'lodash'

function randomCloud () {
  const cloudSize = random(250, 400)
  return {
    width: cloudSize * 1.7,
    height: cloudSize * 1.1,
    top: random(0, 100),
    left: random(0, 100),
    fragments: [...Array(1)].map(() => {
      const fragmentSize = random(90, 100)
      return {
        top: random(0, 10),
        right: random(0, 10),
        width: fragmentSize,
        height: fragmentSize
      }
    })
  }
}

export default {
  name: 'Sky',
  data: () => ({
    clouds: []
  }),
  created () {
    this.clouds = [...Array(10)].map(randomCloud)
  },
  render (h) {
    return h(
      'div',
      { attrs: { class: 'sky' } },
      this.clouds.map(c =>
        h(
          'div',
          {
            class: { cloud: true },
            style: {
              height: `${c.height}px`,
              width: `${c.width}px`,
              top: `${c.top}%`,
              left: `${c.left}%`,
              transform: `translateZ(0)`,
              animation: `fragmentAnimation 5s ease-in-out -${random(
                0,
                5
              )}s infinite alternate`,
              willChange: 'transform'
            }
          },
          c.fragments.map(f =>
            h('img', {
              class: { fragment: true },
              style: {
                top: `${f.top}%`,
                left: `${f.left}%`,
                height: `${f.height}%`,
                width: `${f.width}%`
              },
              attrs: {
                src: '/static/images/cloud.png'
              }
            })
          )
        )
      )
    )
  }
}
</script>

<style>
.cloud {
  position: absolute;
}

@keyframes fragmentAnimation {
  0% {
    transform: translateX(calc(-50% - 25px));
  }
  100% {
    transform: translateX(calc(-50% + 25px));
  }
}
</style>
