<template>
  <div class="island island--6">
    <div id="animation_container_8C2827427FDE458F934E3F5520284FD5" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_8C2827427FDE458F934E3F5520284FD5" style="position: absolute; background-color:rgba(255, 255, 255, 0.00); display:block; z-index: 41; right: 22%; top: 33.25%;"></canvas>
      <div id="dom_overlay_container_8C2827427FDE458F934E3F5520284FD5" style="pointer-events:none; overflow:hidden;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island6',
  mounted () {
    Animation.init('8C2827427FDE458F934E3F5520284FD5')
  }
}
</script>

<style lang="scss">
  .island--6 {
    background-image: url('/public/static/images/island6.png');
  }
</style>
