import createjs from 'createjs'
import * as partials from './partials'

const compositions = Object.values(partials)
  .reduce((comp, partial) => {
    const animation = partial()
    comp[animation.getLibrary().properties.id] = animation
    return comp
  }, {})

const Animations = {
  compositions,
  bootcompsLoaded: [],
  bootstrapListeners: [],
  compositionLoaded (id) {
    Animations.bootcompsLoaded.push(id)
    Animations.bootstrapListeners.forEach(l => l(id))
  },
  getComposition (id) {
    return Animations.compositions[id]
  },
  init (id = null) {
    if (!id) {
      initAll()
    } else {
      initById(id)
    }
  }
}

function handleFileLoad (evt, comp) {
  const images = comp.getImages()
  if (evt && evt.item.type === 'image') {
    images[evt.item.id] = evt.result
  }
}

function handleComplete (evt, comp, animContainer, canvas, domOverlayContainer) {
  // This function is always called, irrespective of the content. You can use the variable 'stage' after it is created in token create_stage.
  const lib = comp.getLibrary()
  const ss = comp.getSpriteSheet()
  const queue = evt.target
  const ssMetadata = lib.ssMetadata

  const { Animation, Stage } = lib

  ssMetadata.forEach(ssM => {
    ss[ssM.name] = new createjs.SpriteSheet({
      'images': [queue.getResult(ssM.name)],
      'frames': ssM.frames
    })
  })

  const exportRoot = new Animation()
  const stage = new Stage(canvas)

  // Registers the 'tick' event listener.
  function fnStartAnimation () {
    stage.addChild(exportRoot)
    createjs.Ticker.setFPS(lib.properties.fps)
    createjs.Ticker.addEventListener('tick', stage)
  }
  // Code to support hidpi screens and responsive scaling.
  function makeResponsive (isResp, respDim, isScale, scaleType) {
    let lastW = 1
    let lastH = 1
    let lastS = 1

    window.addEventListener('resize', resizeCanvas)
    resizeCanvas()

    function resizeCanvas () {
      const w = lib.properties.width
      const h = lib.properties.height
      const iw = window.innerWidth
      const ih = window.innerHeight
      const pRatio = window.devicePixelRatio || 1
      const xRatio = iw / w
      const yRatio = ih / h

      let sRatio = 1
      if (isResp) {
        if ((respDim === 'width' && lastW === iw) || (respDim === 'height' && lastH === ih)) {
          sRatio = lastS
        } else if (!isScale) {
          if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio)
        } else if (scaleType === 1) {
          sRatio = Math.min(xRatio, yRatio)
        } else if (scaleType === 2) {
          sRatio = Math.max(xRatio, yRatio)
        }
      }

      canvas.width = w * pRatio * sRatio * 2
      canvas.height = h * pRatio * sRatio * 2
      canvas.style.width = domOverlayContainer.style.width = animContainer.style.width = w * sRatio * 2 + 'px'
      canvas.style.height = animContainer.style.height = domOverlayContainer.style.height = h * sRatio * 2 + 'px'
      stage.scaleX = pRatio * sRatio * 2
      stage.scaleY = pRatio * sRatio * 2
      lastW = iw
      lastH = ih
      lastS = sRatio
      stage.tickOnUpdate = false
      stage.update()
      stage.tickOnUpdate = true
    }
  }
  makeResponsive(false, 'both', false, 1)
  Animations.compositionLoaded(lib.properties.id)
  fnStartAnimation()
}

function initAll () {
  Object.keys(Animations.compositions).forEach(id => {
    const animContainer = document.getElementById(`animation_container_${id}`)
    if (!animContainer) return
    const canvas = document.getElementById(`canvas_${id}`)
    const domOverlayContainer = document.getElementById(`dom_overlay_container_${id}`)
    const comp = Animations.getComposition(id)
    const lib = comp.getLibrary()
    const loader = new createjs.LoadQueue(false)
    loader.addEventListener('fileload', e => handleFileLoad(e, comp))
    loader.addEventListener('complete', e => handleComplete(e, comp, animContainer, canvas, domOverlayContainer))
    loader.loadManifest(lib.properties.manifest)
  })
}

function initById (id) {
  const animContainer = document.getElementById(`animation_container_${id}`)
  if (!animContainer) return
  const canvas = document.getElementById(`canvas_${id}`)
  const domOverlayContainer = document.getElementById(`dom_overlay_container_${id}`)
  const comp = Animations.getComposition(id)
  const lib = comp.getLibrary()
  const loader = new createjs.LoadQueue(false)
  loader.addEventListener('fileload', e => handleFileLoad(e, comp))
  loader.addEventListener('complete', e => handleComplete(e, comp, animContainer, canvas, domOverlayContainer))
  loader.loadManifest(lib.properties.manifest)
}

export default Animations
