<template>
  <div class="slide__body slide__body--drag-and-drop">
    <div class="drag-and-drop" >
      <div class="drag-and-drop__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.description"></p>
        <span class="button_wrapper">
          <button class="toggleButton" @click="toggleStyles">Ganze Sätze anzeigen</button>
        </span>
      </div>
      <div class="drag-and-drop__body">
        <div class="drag">
          <h2>{{ attributes.container1.name }}</h2>
          <draggable v-if="!solution" v-model="list1" class="drag__area drag__area--col-2" :options="{group:'people'}">
            <DragItem v-for="element in list1"
              :key="element"
              :element="element"
              :applyStyles="applyStyles"
            />
          </draggable>
          <div v-else class="drag__area drag__area--col-2">
            <DragItem v-for="element in attributes.container1.answers"
              :key="element"
              :element="element"
              :success="list1.includes(element)"
              :applyStyles="applyStyles"
            />
          </div>
        </div>
        <div class="drag">
          <h2>{{ attributes.container2.name }}</h2>
          <draggable v-if="!solution" v-model="list2" class="drag__area drag__area--col-2" :options="{group:'people'}">
            <DragItem v-for="element in list2"
              :key="element"
              :element="element"
              :applyStyles="applyStyles"
            />
          </draggable>
          <div v-else class="drag__area drag__area--col-2">
            <DragItem v-for="element in attributes.container2.answers"
              :key="element"
              :element="element"
              :success="list2.includes(element)"
              :applyStyles="applyStyles"
            />
          </div>
        </div>
        <div v-if="!solution" class="drag drag--full-width">
          <draggable v-model="all" class="drag__area" :options="{group:'people', sort: false}">
            <DragItem v-for="element in all"
              :key="element"
              :element="element"
              :applyStyles="applyStyles"
            />
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { shuffle } from 'lodash'

import { slideMixin } from '../slide-mixin'
import DragItem from './DragItem.vue'

export default {
  name: 'DragDropContainer',
  mixins: [slideMixin],
  components: {
    draggable,
    DragItem
  },
  data() {
    return {
      applyStyles: false
    };
  },
  methods: {
    initState () {
      return {
        list1: [],
        list2: [],
        all: shuffle(this.data.attributes.container3.slice())
      }
    },
    getAnswer () {
      return {
        container1: this.list1,
        container2: this.list2
      }
    },
    isUrl (string) {
      return /^https?:\/\//.test(string)
    },
    toggleStyles() {
      this.applyStyles = !this.applyStyles; 
    }
  }
}
</script>

<style lang="scss">
.drag-and-drop {
  width: 100%;
  max-height: 640px;
  overflow-y: scroll;
  &__header {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &--pairing {
      .drag-item span {
        height: 66px;
      }
    }
  }
  .drag {
    position: relative;
    width: 48%;
    background-color: white;
    border-radius: 3px;
    padding: 15px;
    margin: 0 5px;
    border: 1px solid #dadada;
    h2 {
      margin-top: 0px;
    }
    &--full-width {
      background-color: transparent;
      box-shadow: none;
      margin-top: 30px;
      width: 100%;
      border: none;
      min-height: 300px;
      position: relative;
      .sortable-ghost {
        position: absolute;
      }
    }
    &__area {
      height: 100%;
      padding-bottom: 40px;
      .sortable-ghost {
        color: lightgray;
        position: relative;
      }
      div {
        width: fit-content;
        max-width: 47%;
        display: inline-block;
        margin: 5px 15px 5px 0;
      }
      &--col-2 {
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        display: flex;
        div {
          width: 100%;
          max-width: 47%;
        }
        img {
          margin: 0 auto;
        }
      }
    }
    &.stretch {
      span, div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .button_wrapper{
    display: flex;
    flex-direction: row-reverse;
  }
  .toggleButton {
    cursor: pointer;
    z-index:100000;
    pointer-events: all;
    border:1px solid #94c1e3;
    color:#000;
    padding: 8px 15px;
    background: transparent;
    margin-right: 5px;
  }
}
</style>
