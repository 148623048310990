<template>
  <div class="drag-item" :class="{ 'drag-item--success': success, 'full-size': applyStyles }">
    <img v-if="isUrl(element)" :src="element">
    <span v-else :title="element">{{element}}</span>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      required: true
    },
    success: {
      required: false,
      default: false
    },
    applyStyles: {
      required: false,
      default: false
    }
  },
  methods: {
    isUrl (string) {
      return /^https?:\/\//.test(string)
    }
  }
}
</script>

<style lang="scss">
.drag-item {
  white-space: initial;
  vertical-align: top;
  span {
    display: block;
    max-width: 100%;
    width: fit-content;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 3px 10px;
    background-color: #94c1e3;
    color: #000;
    max-height: 66px;
  }
  img {
    display: block;
    height: 100px;
    width: auto;
  }
  &--success {
    span {
      background-color: #52c652;
    }
    img {
      border: 1px solid #52c652;
    }
  }
}
.full-size {
  max-width:100% !important;
  span {
    max-height:100% !important;
    -webkit-line-clamp: initial !important;
  }
}
</style>
