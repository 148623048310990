/**
 * The slide mixin provides the common logic for all slides to
 * serialize the current state of a slide and restore the state
 * from the serialized Object
 *
 * This is used because a slide handles its state by itself
 * but we also want to persist it in vuex/local storage
 */

import { isEmpty, pick } from 'lodash'

export const slideMixin = {
  data () {
    if (isEmpty(this.state)) {
      const state = this.initState()
      // create array of keys outside of vue.js tracking
      // calling initState in the computed component `hash`
      // leads to an infinite loop on the survey slide
      // (which is using metadata for initialization)
      this.__stateKeys = Object.keys(state)
      return state
    } else {
      return this.state
    }
  },
  props: {
    /**
     * the slides data cumming from the backend
     */
    data: {
      type: Object,
      default: () => ({})
    },
    /**
     * the slides current state is passed as prop
     * so a slides state can be serialized to vuex/internal storage
     * and can be restored on page reload
     *
     * if there there is no state prop the slide will be initialized
     * from the init state method
     */
    state: {
      type: Object,
      default: null
    },
    /**
     * solution object which is used to show the solution to the
     * student (might soon be deprecated)
     */
    solution: {
      type: [Boolean, Object],
      default: null
    },
    /**
     * context can be used to handle differences in slide presentation
     * between student, trainer and presentation mode
     */
    context: {
      type: String,
      default: 'student'
    }
  },
  methods: {
    /**
     * a answer method has to be provided by each single slide
     * this answer method is called when a slide times out and
     * provides the students solution to the backend
     */
    getAnswer () {
      console.warn(`Answer method not implemented in the component:`, this)
      return null
    },
    /**
     * the init state method has to be provided by each slide to, it basically
     * follows the vue.data function and returns the base state of the slide
     */
    initState () {
      console.warn(`Init method not implemented in the component:`, this)
      return {}
    },
    resetState () {
      Object.assign(this, this.initState())
    }
  },
  computed: {
    attributes () {
      return this.data.attributes
    },
    /**
     * get the current serializable state
     */
    hash () {
      return pick(this.$data, this.__stateKeys)
    }
  },
  watch: {
    /**
     * watch for hash changes and emit an event on the DynamicSlide
     */
    hash: {
      immediate: true,
      deep: true,
      handler () {
        // originally we prevented an infinite loop which might occur in
        // development with hot reloading by deep comparing newHash against
        // oldHash but this doesn't work as expected.

        // See https://vuejs.org/v2/api/#vm-watch
        // Note: when mutating (rather than replacing) an Object or an Array,
        // the old value will be the same as new value because they reference
        // the same Object/Array. Vue doesn’t keep a copy of the pre-mutate value.

        this.$parent.$parent.$emit(
          'state',
          JSON.parse(JSON.stringify(this.hash))
        )
      }
    }
  }
}
