<template>
  <div class="slide__body slide__body--slider">
    <div class="slider">
      <div class="slider__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="slider__body">
        <div class="range__output">
          <span>{{ rangeValue }}</span>
        </div>
        <div class="range__container">
          <span class="rangeMin">{{ attributes.sliderMinValue }}</span>
          <input
            type="range"
            class="range"
            v-model="rangeValue"
            :min="attributes.sliderMinValue"
            :max="attributes.sliderMaxValue"
            :step="attributes.stepRange"
          >
          <span class="rangeMax">{{ attributes.sliderMaxValue }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'Slider',
  mixins: [slideMixin],
  methods: {
    initState () {
      return {
        rangeValue: Math.floor(
          (this.data.attributes.sliderMinValue +
            this.data.attributes.sliderMaxValue) /
            2
        )
      }
    },
    getAnswer () {
      return this.rangeValue
    }
  }
}
</script>

<style lang="scss">
.slider {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .range {
    &__output {
      margin-bottom: 30px;
      position: relative;
      span {
        font-weight: bold;
        font-size: 4rem;
      }
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -250px;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 550px;
      span {
        margin: 0 15px;
      }
      input {
        width: 500px;
      }
      input[type='range'] {
        &::-moz-focus-outer {
          border: 0;
        }
      }
    }
  }
}

input[type='range'].range {
  -webkit-appearance: none;
  width: 100%;
  margin: 17px 0;
}
input[type='range'].range:focus {
  outline: none;
}
input[type='range'].range::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.38),
    0px 0px 1px rgba(13, 13, 13, 0.38);
  background: #95c0e2;
  border-radius: 0px;
  border: 0px solid rgba(0, 36, 37, 0);
}
input[type='range'].range::-webkit-slider-thumb {
  box-shadow: 0.8px 0.8px 5.5px rgba(0, 0, 0, 0.37),
    0px 0px 0.8px rgba(13, 13, 13, 0.37);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 39px;
  width: 19px;
  border-radius: 2px;
  background: #1f4465;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -17px;
}
input[type='range'].range:focus::-webkit-slider-runnable-track {
  background: #95c0e2;
}
input[type='range'].range::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.38),
    0px 0px 1px rgba(13, 13, 13, 0.38);
  background: #95c0e2;
  border-radius: 0px;
  border: 0px solid rgba(0, 36, 37, 0);
}
input[type='range'].range::-moz-range-thumb {
  box-shadow: 0.8px 0.8px 5.5px rgba(0, 0, 0, 0.37),
    0px 0px 0.8px rgba(13, 13, 13, 0.37);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 39px;
  width: 19px;
  border-radius: 2px;
  background: #1f4465;
  cursor: pointer;
}
input[type='range'].range::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range'].range::-ms-fill-lower {
  background: #95c0e2;
  border: 0px solid rgba(0, 36, 37, 0);
  border-radius: 0px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.38),
    0px 0px 1px rgba(13, 13, 13, 0.38);
}
input[type='range'].range::-ms-fill-upper {
  background: #95c0e2;
  border: 0px solid rgba(0, 36, 37, 0);
  border-radius: 0px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.38),
    0px 0px 1px rgba(13, 13, 13, 0.38);
}
input[type='range'].range::-ms-thumb {
  box-shadow: 0.8px 0.8px 5.5px rgba(0, 0, 0, 0.37),
    0px 0px 0.8px rgba(13, 13, 13, 0.37);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 39px;
  width: 19px;
  border-radius: 2px;
  background: #1f4465;
  cursor: pointer;
}
input[type='range'].range:focus::-ms-fill-lower {
  background: #95c0e2;
}
input[type='range'].range:focus::-ms-fill-upper {
  background: #95c0e2;
}
</style>
