<template>
  <div class="slide__body slide__body--social-media">
    <div class="social-media">
      <div class="social-media__header">
        <h1>{{ data.name }}</h1>
      </div>
      <div class="social-media__body">
        <div class="social-media__tasks">
          <ul>
            <li v-for="(task, index) in activeTasks"
              :key="index"
              :class="{ 'social-media__tasks--done' : finished || (index < activeTasks.length - 1) }"
            >
              <span>Aufgabe {{ index + 1 }}</span>
              <div v-html="getTaskText(index)"></div>
              <div
                class="social-media__tasks__hinttext"
                v-if="showHint && index === activeTasks.length - 1"
              >
                <i class="far fa-question-circle"></i>
                {{ task.hint }}
              </div>
            </li>
          </ul>
        </div>
        <div class="social-media__image" :class="{ 'shake' : isShaking}">
          <!-- We have to render all images to the dom because of how image-map-resizer works internally (caching) -->
          <template v-for="(task, index) in attributes.tasks">
            <img
              v-if="index === currentTaskIndex"
              @click="wrongClick"
              :key="`img${index}`"
              :src="task.image"
              :usemap="`#image-map-${index}`"
            >
            <map
              v-if="index === currentTaskIndex"
              :name="'image-map-' + index"
              :key="`map${index}`"
            >
              <area :coords="task.map" :shape="task.shape" @click="nextImage">
            </map>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'
import imageMapResize from 'image-map-resizer'

export default {
  name: 'SocialMedia',
  mixins: [slideMixin],
  methods: {
    initState: () => ({
      currentTaskIndex: 0,
      finished: false,
      falseClicks: 0,
      isShaking: false,
      showHint: false
    }),
    nextImage () {
      this.showHint = false
      this.currentTaskIndex++
      this.$nextTick(imageMapResize)
      if (this.currentTaskIndex === this.attributes.tasks.length) {
        this.finished = true
      }
    },
    wrongClick () {
      this.falseClicks++
      this.isShaking = true
      this.showHint = true
      setTimeout(() => {
        this.isShaking = false
      }, 500)
    },
    getAnswer () {
      return {
        rightClicks: this.currentTaskIndex,
        falseClicks: this.falseClicks
      }
    },
    getTaskText (index) {
      const task = this.attributes.tasks
        .slice(0, index + 1)
        .reverse()
        .find(task => /<section.*>(.+)<\/section>/.test(task.text))

      return (task || this.attributes.tasks[0]).text
    }
  },
  computed: {
    activeTasks () {
      return this.attributes.tasks
        .slice(0, this.currentTaskIndex + 1)
        .filter(task => task.text)
    }
  },
  mounted () {
    imageMapResize()
  }
}
</script>

<style lang="scss">
.social-media {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    min-height: 200px;
    display: flex;
    width: 100%;
  }
  &__tasks {
    width: 35%;
    padding-right: 15px;
    ul {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 5px;
        &:before {
          content: '\f058';
          position: absolute;
          top: 0;
          left: -20px;
          font-weight: 100;
          font-family: 'Font Awesome\ 5 Free';
          font-size: 1rem;
          opacity: 0;
        }
        &.social-media__tasks--done {
          span {
            font-weight: bold;
          }
          &:before {
            opacity: 1;
          }
        }
        p {
          margin: 0 0 10px 0;
        }
      }
    }
    &__hinttext {
      font-style: italic;
      color: red;
    }
  }
  &__image {
    width: 65%;
    max-height: 600px;
    overflow-y: scroll;
    transition: border 300ms;
    border: 2px solid transparent;
    &.shake {
      border-color: red;
      animation: shake 500ms infinite both;
      pointer-events: none;
    }
    img {
      cursor: pointer;
      width: 100%;
    }
    map {
      cursor: pointer;
    }
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateZ(0) rotate(0deg);
  }
  20%, 60% {
    transform: translate3d(-5px, 0, 0) rotate(-2deg);
  }
  40%, 80% {
    transform: translate3d(5px, 0, 0) rotate(2deg);
  }
}
</style>
