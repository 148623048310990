import cjs from 'createjs'
/* eslint-disable */

export function createAnimation () {
var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"quelle.xs_atlas_", frames: [[0,0,2948,2647],[0,5298,2948,2647],[0,2649,2948,2647]]}
];


// symbols:



(lib.quelle01 = function() {
	this.spriteSheet = ss["quelle.xs_atlas_"];
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.quelle02 = function() {
	this.spriteSheet = ss["quelle.xs_atlas_"];
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.quelle03 = function() {
	this.spriteSheet = ss["quelle.xs_atlas_"];
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.quelle03_1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.quelle03();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,2948,2647);


(lib.quelle02_1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.quelle02();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,2948,2647);


(lib.quelle01_1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.quelle01();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,2948,2647);


// stage content:
(lib.Animation = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_3
	this.instance = new lib.quelle03_1("synched",0);
	this.instance.parent = this;
	this.instance.setTransform(156,140,0.106,0.106,0,0,0,1473.9,1323.6);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(6).to({_off:false},0).wait(3));

	// Ebene_2
	this.instance_1 = new lib.quelle02_1("synched",0);
	this.instance_1.parent = this;
	this.instance_1.setTransform(156,140,0.106,0.106,0,0,0,1473.9,1323.6);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(3).to({_off:false},0).to({_off:true},3).wait(3));

	// Ebene_1
	this.instance_2 = new lib.quelle01_1("synched",0);
	this.instance_2.parent = this;
	this.instance_2.setTransform(156,140,0.106,0.106,0,0,0,1473.9,1323.6);

	this.timeline.addTween(cjs.Tween.get(this.instance_2).to({_off:true},3).wait(6));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(156.1,140,311.8,280);
// library properties:
lib.properties = {
	id: '8C2827427FDE458F934E3F5520284FD5',
	width: 312,
	height: 280,
	fps: 24,
	color: "#0099FF",
	opacity: 0.00,
	manifest: [
		{src:"/static/images/quelle.xs_atlas_.png", id:"quelle.xs_atlas_"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	cjs.Stage.call(this, canvas);
}).prototype = p = new cjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }
return {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};
}
