<template>
  <div class="slide__body slide__body--drag-and-drop">
    <div class="drag-and-drop">
      <div class="drag-and-drop__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.description"></p>
      </div>
      <div class="drag-and-drop__body drag-and-drop__body--pairing">
        <div class="drag stretch">
          <h2>Satzteil 1</h2>
          <draggable v-if="!solution" v-model="leftSideAnswers" class="drag__area" :options="{group:'people'}">
            <div v-for="(element, index) in leftSideAnswers" :key="index" class="drag-item">
              <span :title="element">{{element}}</span>
            </div>
          </draggable>
          <div v-else class="drag__area">
            <div v-for="(element, index) in leftSide"
              :key="index"
              class="drag-item"
              :class="{ 'drag-item--success': isSuccess(index) }"
            >
              <span :title="element">{{element}}</span>
            </div>
          </div>
        </div>
        <div class="drag stretch">
          <h2>Satzteil 2</h2>
          <draggable v-if="!solution" v-model="rightSideAnswers" class="drag__area" :options="{group:'people'}">
            <div v-for="(element, index) in rightSideAnswers" :key="index" class="drag-item">
              <span :title="element">{{element}}</span>
            </div>
          </draggable>
          <div v-else class="drag__area">
            <div v-for="(element, index) in rightSide"
              :key="index"
              class="drag-item"
              :class="{ 'drag-item--success': isSuccess(index) }"
            >
              <span :title="element">{{element}}</span>
            </div>
          </div>
        </div>
        <div v-if="!solution" class="drag drag--full-width">
          <draggable v-model="pool" class="drag__area" :options="{group:'people', sort: false }">
            <div v-for="(element, index) in pool" :key="index" class="drag-item">
              <span :title="element">{{element}}</span>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { slideMixin } from './slide-mixin'
import { shuffle } from 'lodash'

export default {
  name: 'DragDropPairing',
  mixins: [slideMixin],
  components: {
    draggable
  },
  methods: {
    initState () {
      return {
        leftSideAnswers: [],
        rightSideAnswers: [],
        pool: shuffle([
          ...Object.keys(this.data.attributes.answers),
          ...Object.values(this.data.attributes.answers)
        ])
      }
    },
    getAnswer () {
      return this.leftSideAnswers.reduce((result, key, i) => {
        result[key] = this.rightSideAnswers[i]
        return result
      }, {})
    },
    isSuccess (index) {
      /**
       * Checks if a shown solution pair has been answered correctly.
       * We pass the index so it works for the left and right side
       * of the solution.
       */

      // get the string of the left side solution
      const leftSide = this.leftSide[index]
      // find the index of the left side part answer
      const answerIndex = this.leftSideAnswers.indexOf(leftSide)
      // an actuall left side could be placed in the right so we might not find it
      if (answerIndex === undefined) return false

      // get the actuall solution for the left side
      const solution = this.attributes.answers[leftSide]

      // get the answer on the specific index
      const answer = this.rightSideAnswers[answerIndex]

      // compare them
      return solution === answer
    }
  },
  computed: {
    leftSide () {
      return Object.keys(this.attributes.answers)
    },
    rightSide () {
      return Object.values(this.attributes.answers)
    }
  }
}
</script>
