<template>
  <div class="preview">
    <div class="preview__header">
      <Dashboard :team="team" />
      <slot />
    </div>
    <Scene :activeTheme="activeTheme" :slideTypes="slideTypes" />
  </div>
</template>

<script>
import Dashboard from './Dashboard.vue'
import Scene from './Scene.vue'

export default {
  name: 'Preview',
  components: {
    Dashboard,
    Scene
  },
  props: ['team', 'activeTheme', 'slideTypes']
}
</script>

<style lang="scss">
.preview {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(#559cd2, #69afdb);
  &__header {
    width: 1300px;
    margin: 0 auto;
  }
}
</style>
