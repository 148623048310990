<template>
  <div class="island island--4">
    <div id="animation_container_195FE25B18E244838840574ABEAECB15" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_195FE25B18E244838840574ABEAECB15" style="position: absolute; background-color:rgba(255, 255, 255, 0.00); display:block; z-index: 51; right: 14%; top: 19.5%;"></canvas>
      <div id="dom_overlay_container_195FE25B18E244838840574ABEAECB15" style="pointer-events:none; overflow:hidden;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island4',
  mounted () {
    Animation.init('195FE25B18E244838840574ABEAECB15')
  }
}
</script>

<style lang="scss">
  .island--4 {
    background-image: url('/public/static/images/island4.png');
  }
</style>
