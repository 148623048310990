<template>
  <div class="slide__body slide__body--cryptochallenge">
    <div class="cryptochallenge">
      <div class="cryptochallenge__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="cryptochallenge__body">
        <div class="question">
          <p v-html="questions[activeQuestion]"></p>
        </div>
        <form v-on:submit.prevent="checkAnswer">
          <div class="input-button">
            <div class="input input-button__input" :class="{'input--state-disabled': questions.length <= activeQuestion }">
              <input v-model="input" type="text" class="input__field" placeholder="Antwort" :disabled="questions.length <= activeQuestion">
            </div>
            <div class="input-button__button">
              <button type="submit" class="button button--color-blue" :class="{'button--state-disabled': questions.length <= activeQuestion }" @click="checkAnswer">
                <span>Senden</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="agb">
        <button class="button button--color-red button--size-smal" @click="openOverlay">
          <span v-html="this.attributes.agbButtonText === '' ? 'AGB' : this.attributes.agbButtonText"></span>
        </button>
      </div>
      <div class="agb__overlay" :class="{ 'agb__overlay--open': overlayOpen }">
        <div class="agb__content">
          <div class="agb__close" @click="closeOverlay">x</div>
          <div v-html="attributes.agb"></div>
          <label class="checkbox__container">AGB
            <input class="checkbox__field" type="checkbox" v-model="agbChecked">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'Cryptochallenge',
  mixins: [slideMixin],
  methods: {
    initState: () => ({
      input: '',
      activeQuestion: 0,
      overlayOpen: false,
      agbChecked: false
    }),
    checkAnswer (index) {
      if (this.input.length > 0) {
        this.activeQuestion++
      }
      this.input = ''
    },
    openOverlay () {
      this.overlayOpen = true
    },
    closeOverlay () {
      this.overlayOpen = false
    },
    getAnswer () {
      return {
        agbChecked: this.agbChecked,
        questionCounter: this.activeQuestion
      }
    }
  },
  computed: {
    questions () {
      return this.attributes.questions
    }
  }
}
</script>

<style lang="scss">
.cryptochallenge {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .agb {
    position: absolute;
    bottom: 30px;
    left: 15px;
    z-index: 1;
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 100;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      &--open {
        visibility: visible;
        opacity: 1;
      }
    }
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      color: black;
      font-size: 1.3rem;
      padding: 15px 20px;
      cursor: pointer;
    }
    &__content {
      position: relative;
      background-color: white;
      border-radius: 3px;
      width: 80%;
      margin: 0 auto;
      min-height: 200px;
      margin-top: 40px;
      padding: 15px;
    }
  }
}
</style>
