<template>
  <div class="slide__body slide__body--cloze">
    <div class="cloze">
      <div class="cloze__header">
        <h1>{{ data.name }}</h1>
      </div>
      <div class="cloze__body">
        <template v-for="(part, index) in attributes.text">
          <span :key="'span' + index" v-html="part"></span>
          <template v-if="!solution">
            <input v-if="index < attributes.text.length - 1"
              :key="'input' + index"
              class="cloze__input"
              type="text"
              v-model="solutions[index]"
            >
          </template>
          <template v-else>
            <input v-if="index < attributes.text.length - 1"
              :key="'solution' + index"
              type="text"
              :class="{
                'cloze__input': true,
                'cloze__input--success': attributes.answers[index] === solutions[index]
              }"
              :answer="attributes.answers[index]"
              :solution="solutions[index]"
              :value="attributes.answers[index]"
            >
          </template>
        </template>
      </div>
      <div class="cloze__footer" v-if="!solution">
        <span v-for="(answer, index) in allAnswers" :key="index" :class="{'used' : checkAnswer(answer)}">{{ answer }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { shuffle } from 'lodash'
import { slideMixin } from './slide-mixin'

export default {
  name: 'Cloze',
  mixins: [slideMixin],
  methods: {
    initState () {
      return {
        solutions: [],
        allAnswers: shuffle([
          ...this.data.attributes.answers,
          ...this.data.attributes.wrongTextSolutions
        ])
      }
    },
    checkAnswer (answer) {
      return this.solutions.includes(answer)
    },
    getAnswer () {
      return this.solutions
    }
  }
}
</script>

<style lang="scss">
.cloze {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 80%;
    margin: 0 auto;
    min-height: 200px;
    min-height: 200px;
  }
  &__footer {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
    span {
      margin-right: 30px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1f4465;
        height: 3px;
        width: 0%;
        transition: .3s;
      }
      &.used {
        &:after {
          width: 100%;
        }
      }
    }
  }

  &__input {
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    background-color: transparent;
    font-size: 1.3rem;
    outline: 0;
    color: #1f4465;
    &--success {
      color: green;
      border-bottom: 1px solid green;
    }
  }
}
</style>
