<template>
  <PresentationWrapper>
    <div class="slide__body slide__body--text-image">
      <div class="text-image">
        <div class="text-image__header" v-if="!attributes.hideName">
          <h1>{{ data.name }}</h1>
        </div>
        <div class="text-image__body">
          <div
            v-if="/<section.*>[\s\S]+<\/section>/.test(attributes.text)"
            class="text-image__text ezrichtext-field"
            v-html="attributes.text"
          ></div>
          <div v-if="attributes.image" class="text-image__media">
            <img :src="attributes.image" alt>
          </div>
          <div v-if="attributes.video" class="text-image__media">
            <video ref="video">
              <source :src="attributes.video.uri" :type="attributes.video.mimeType">
            </video>
          </div>
          <div v-if="attributes.youtubeUrl" class="text-image__media text-image__media-youtube">
            <iframe
              :src="`https://www.youtube.com/embed/${attributes.youtubeUrl}?rel=0&autoplay=${autoplay}&showinfo=0&controls=0`"
              allow="autoplay; fullscreen"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </PresentationWrapper>
</template>

<script>
import { slideMixin } from './slide-mixin'
import { presentationMixin } from './presentation'

export default {
  name: 'TextImage',
  mixins: [slideMixin, presentationMixin],
  data: () => ({
    autoplay: 0
  }),
  computed: {
    isVideo () {
      return this.attributes.youtubeUrl !== null || this.attributes.video !== null
    },
    videoShouldStart () {
      // attributes.shouldStart is undefined or true
      // so we explicitly convert it to boolean with !!
      return this.isVideo && !!this.attributes.shouldStart
    }
  },
  watch: {
    videoShouldStart (newValue, oldValue) {
      if (
        ['presentation', 'student'].includes(this.context) &&
        newValue &&
        !oldValue
      ) {
        this.autoplay = 1
        if (this.$refs.video) {
          this.$refs.video.play()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.text-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__text {
    flex: 1;
  }
  &__media {
    flex: 1;
    max-height: 100%;
    max-width: 100%;
    img {
      max-height: 100%;
      max-width: 100%;
    }
    &-youtube {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 40%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
    video {
      width: 100%;
    }
  }
}
</style>
