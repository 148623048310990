<template>
  <div class="slide__body slide__body--survey-result">
    <div class="survey-result">
      <div class="survey-result__header">
        <h1>{{ data.name }}</h1>
      </div>
      <div class="survey-result__body">
        <div class="survey-result__chart">
          <chart :data="chartData" :options="chartOptions"></chart>
        </div>
        <div class="survey-result__chips">
          <div
            class="survey-result__chip"
            v-for="(chip, index) in attributes.additionalSurveys"
            :key="index"
          >
            <h4>{{ chip.title }}</h4>
            <span>{{ chip.result }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'
import Chart from './Chart.vue'

export default {
  name: 'SurveyResult',
  mixins: [slideMixin],
  components: {
    Chart
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: 18,
              autoSkip: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1
            }
          }
        ]
      },
      maintainAspectRatio: false
    }
  }),
  computed: {
    chartData () {
      const [labels, data] = Object.entries(
        this.attributes.mainSurveyResult || {}
      ).reduce(
        (prev, [label, date]) => {
          prev[0].push(label)
          prev[1].push(date)
          return prev
        },
        [[], []]
      )

      return {
        labels,
        datasets: [
          {
            label: 'Ergebnisse',
            backgroundColor: '#559cd2',
            hoverBackgroundColor: '#1f4465',
            data
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
.survey-result {
  width: 100%;
  &__header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__chips {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  &__chip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 3px;
    padding: 15px;
    flex: 1;
    margin: 0 15px;
    border: 1px solid #dadada;
    h4 {
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: 10px;
      text-align: center;
    }
    span {
      font-weight: 900;
      display: block;
      text-align: center;
      font-size: 1.4rem;
    }
  }
}
</style>
