<template>
  <div class="slide__body slide__body--map-tyrol">
    <div class="map-tyrol">
      <div class="map-tyrol__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="map-tyrol__body">
        <div class="drag">
          <img src="/static/images/AK-TIROL.png" alt="AK Tirol" class="ak-logo">
          <draggable v-model="districts" class="drag__area" :options="{group:'citys', sort: false, setData}">
            <div v-for="(district, index) in districts"
              class="drag__item"
              :class="{'drag__item--used': isUsed(district.name.replace(' ', '_'))}"
              :key="index"
              :id="`drag${district.name.replace(' ', '_')}`"
            >{{district.name}}</div>
          </draggable>
        </div>
      </div>
      <div class="map-tyrol__footer">
        <svg viewBox="0 0 1050 600">
          <path v-for="district in districts"
            :key="district.id"
            :id="district.id"
            :class="{ 'district': true, 'district--active': !solution && isActive(district.id)}"
            :d="`${district.path}`"
            @dragenter="toggleMouseOver"
            @dragleave="toggleMouseOver"
            @drop="onDrop"
            @dragover="onDragover"
          />
          <text v-for="district in districts"
            :key="`text${district.id}`"
            :transform="`matrix(${district.textTrandformMatrix})`"
            :id="`text${district.id}`"
            :class="{ 'district-text': true, 'district-text--solution': solution }"
          >{{ solution ? district.name : createString(answers[district.id]) }}</text>
          <circle cx="243" cy="293.9" r="8"/>
          <circle cx="185.7" cy="344.2" r="8"/>
          <circle cx="235.7" cy="177.6" r="8"/>
          <circle cx="549" cy="246.2" r="8"/>
          <circle cx="691.6" cy="142.9" r="8"/>
          <circle cx="670" cy="162.9" r="8"/>
          <circle cx="766.3" cy="198.9" r="8"/>
          <circle cx="883.7" cy="493.6" r="8"/>
          <circle cx="380" cy="270" r="8"/>
          <circle cx="438" cy="275" r="8"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { slideMixin } from './slide-mixin'

const paths = [
  'M324.52,232.54l-5.72,8.22-1.63,12.35,1.63,6.58,11.45,2.46-.82,5.76,9-.82,3.27,4.11v16.45l-3.27,3.3,4.09,8.22,6.54,1.65L345,307.4l-9,3.29-4.91,4.11.82,6.58,1.64,11.52,4.9,6.58-.82,13.16,11.45,23.86,5.72,9.05L345,411.87l18,12.34,4.91-.82.81,10.69-9.81,6.58L351.5,439l-1.63,7.4-4.09.82.82,11.52-9.81,16.45-.82,7.41,4.09,7.4-13.9,7.4-5.72,18.1-3.27-2.47-5.72,1.65-24.52-4.12-11.45,4.94-13.89-9-8.17,2.47-9-9.87-9,4.93-9-1.64-.81-4.94,12.25-10.69,6.54-4.11-1.63-8.23,4.91-4.11.81-10.7-5.72-4.93v-8.23l5.72-9.05-2.45-8.23,5.72-15.63-1.63-13.16,9-8.22L251,373.21l-1.63-7.41-9-6.58-6.54-1.64-1.64-3.29L219.9,341.13l4.91-4.94-6.54-1.65-4.09-5.76,1.64-2.47.82-18.92-1.64-.82-1.63-3.29-.82-7.4L206,293.41l-7.36-9.05-4.9,6.58-7.36-.82-4.9-4.94,1.63-14-9-8.23v-.82l4.91-1.64,13.07,13.16L209.28,277l1.63-12.34h-6.54l-1.63-9.05-5.72.82v-8.22l9-.82,2.45,3.29,9-8.23,5.72,2.46,4.91-11.51,9,2.47,9-2.47,3.27-9.05,14.71-.82v7.4l13.08,15.63,16.35-6.58,3.27-7.4,20.43,4.11,1.64-17.27,7.35.82-1.64,12.34',
  'M448,262.15,452,271.2l5.72-.82,4.09,4.94,1.63,9v3.29l-7.36,5.76h-6.54v3.29l13.9,13.17-14.71-4.12-4.91,4.12L443,309V297.53l.82-2.47-2.45-1.64-12.26,1.64,1.64-2.47-9-8.23h-5.72l.82-12.33,9.81-10.7-.82-13.16,8.18-8.23,22.06,7.41-.81,11.51L448,262.15',
  'M464.3,221l22.06,9.88-1.63,7.4L479,239.94l-.82,4.12,12.26-1.65L497,245.7l13.9,1.65-1.64,4.11,4.91,9h2.45l.82,5.76,3.27-.83,5.72,7.41,12.26,15.63L542,315.62l-11.44,3.29,1.63,8.23-3.27,4.12,3.27,4.93-13.07,9.05v16.45l4.9,7.4-1.64,3.29,4.09,14L524,389.66l-1.64,7.4-5.72,2.47-.81,4.94-11.45,4.11-17.16,9.05L479,407.76l-10.62-3.29-2.45,7.4-5.73,1.64-5.72,8.23-10.62,4.12-6.54-2.47L430,413.51l-7.35,1.65-4.09-3.29L413.62,416l-5.72-.83L381.75,425l-4.09-4.11L371.12,425l-3.27-1.64-4.9.82-18-12.34,9.81-26.32-5.73-9.05-11.44-23.86.82-13.16-4.91-6.58-1.63-11.52-.82-6.58,4.9-4.11,9-3.29,4.08-6.58-6.54-1.65L338.43,291l3.27-3.3V271.2l-3.27-4.11-9,.82.82-5.76-11.44-2.46-1.64-6.58,1.64-12.35,5.72-8.22,1.63-12.34,13.08.82H358l4.9-7.4,9-4.11,15.53-5.76,13.89,1.65.82,2.46-10.63,14,18.8-1.64,4.09-12.34,12.26-4.93,2.45-4.94,26.16,3.29,4.08,2.47-2.45,6.58L464.3,221m-37.6,40.31L416.89,272l-.82,12.33h5.72l9,8.23-1.64,2.47,12.26-1.64,2.45,1.64-.82,2.47V309l.82.83,4.9-4.12,14.72,4.12-13.9-13.17v-3.29h6.54l7.36-5.76v-3.29l-1.63-9-4.09-4.94-5.73.82L448,262.15l7.35-3.29.82-11.51-22.07-7.41-8.17,8.23.82,13.16',
  'M779,89.4l-.82,9,9,12.34L797,122.31l8.17-2.47,4.09-2.46L821.49,119l-.82,12.34,25.35,9,4.08,4.11-12.26,14,8.17,5.76,1.64,11.52,2.45,1.64,9.81,14L848.47,198h-9.81v15.63l-2.45,4.11-15.54,14-9.81-2.47-6.53,3.29-5.72-1.64-9.81,11.51,6.53,9.87-.81,4.94-5.72,4.93-11.45-.82-9.8,6.58-11.45.83-12.26-7.41-5.72.82-6.54-3.29-10.63,14-3.26-2.47-4.91,3.29-2.45-5.76-3.27.83-6.54,4.93-10.63-.82-6.53,6.58-9.81-9.05-11.45,14-3.27-1.64-11.44,4.11-9-.82,6.54-23L657.2,258l8.17-18.92-5.72-23,4.9-4.94h6.54L667,200.46h4.9l1.64-4.94.81.82,6.54-2.46-1.63-5.76,6.54-1.65,7.35-.82,5.72,4.11.82,4.12,3.27,2.46,3.27-1.64,10.63-4.94,13.89,2.47h5.72l4.09-13.16-.82-27.15-2.45-6.58-.82-3.29,4.91-4.94-.82-2.46L746.3,119l-4.09-4.12,1.63-8.22,8.18-4.94-4.91-10.69,8.18,3.29L779,89.4',
  'M724.22,71.3l-7.35,18.93L721,96l22.89-7.41,3.27-.82v3.29l4.9,10.69-8.17,4.94-1.64,8.22,4.09,4.12-5.72,15.63.82,2.46-4.91,4.94.82,3.29,2.45,6.58.82,27.15-4.09,13.16h-5.72l-13.9-2.47-10.62,4.94L703,196.35l-3.27-2.47-.82-4.11-5.72-4.12-7.36.83-6.54,1.64,1.64,5.76-6.54,2.47-.82-.83-1.63,4.94H667l4.09,10.69h-6.54l-4.9,4.94,5.72,23L657.2,258,644.93,263,631,257.22l-9.81,1.65-6.54-3.29-1.64-12.34-9.8-8.23-13.08-10.69L586.9,226V221l-5.72,2.46v-4.93l-14.72-10.69,2.46-9.88,15.53-6.58.81-6.58.82-14,3.27-3.29-2.45-7.41h-9L573,156l1.64-4.94-13.08-1.64,8.17-8.23-.81-6.58,21.25,4.94,4.09-9.88,12.26-2.46,25.34-2.47,10.62-4.94,4.09,6.58L658.83,124l7.36,4.11,20.43.82,9.81-3.29-.82,6.58,9-3.29-2.45-15.63-3.27-7.4-7.36-20.57h7.36l8.17-4.11,14.72-15.63,2.45,5.75',
  'M198.65,284.36l7.36,9.05,6.53,2.47.82,7.4,1.64,3.29,1.63.83-.81,18.92-1.64,2.46,4.09,5.76,6.54,1.65-4.91,4.94,12.26,13.16,1.64,3.29,6.53,1.64,9,6.58,1.63,7.41,8.18,18.92-9,8.23,1.64,13.16-5.72,15.63,2.45,8.22-5.72,9.05v8.23l5.72,4.94-.82,10.69-4.9,4.11,1.63,8.23-6.54,4.11-2.45-4.93-19.62-9.88,1.64-4.93-9.81-6.58-4.91,4.11-8.17.82L170,476.86l.82-5.76-17.17.82L148,466.16l-3.27,1.65-1.63-13.16,7.35-14.81,1.63-9.87-9.8-7.4-10.63-4.12.82-7.4L121,398.71l-5.72,4.94-6.54-.83-8.17,17.28L94,422.57l2.45,10.69-6.54,2.47-17.16-6.58L67.05,445.6l1.63,14H56.43l-8.18,6.57H40.9l-1.64-11.51-9.81-9.87L27,430l9-6.58,7.36-19.75h4.08l-.81-9.87-9.81-8.22,4.08-3.29,7.36-9.05-1.63-6.59-4.91-.82-.82-6.58,9-16.45,9-2.47v-4.11l7.35-.82-1.63-5.76,5.72-2.47L67.87,323,72,325.5l22.89-5.76,4.91-3.29,13.89.82,4.09-4.94,8.17,4.94,6.54-1.65,3.27-6.58L143.88,291l10.63-5.76,4.09,4.11,16.34,2.47,3.27-7.41,3.27.83,4.91,4.94,7.35.82,4.91-6.59',
  'M801.87,345.24l7.36,9.87,6.54-2.47,7.35.83.82,4.11,8.18,1.64,12.26,9.87,13.89,13.17,5.72,14,13.08-1.65,4.09,3.3-4.91,2.46-9,17.28,4.09,13.16.82,4.11,4.9,2.47,8.18,7.4,2.45,5.76,4.9,2.47,4.09,2.47,4.9,3.29L900,462.87l3.27,4.12-.82,14.8,14.71,9,3.27,7.4,2.46,1.65h.81l18,11.52.82,4.93L931.84,527l-10.63-5.76-13.9,8.23-4.9,4.93h-9l-.82-3.29h-4.9L873,535.26l-12.26,4.94-.81,9.87,4.08,5.76h-9l-.82,21.39-16.34,2.47-3.27-4.94L815,579.69l-5.72-4.12-11.44-9-11.45.82-1.63-4.94-10.63-.82-4.9-4.94-14.71-9.87-6.54-26.32-23.71-4.12v-14.8l7.36-12.34-3.27-1.65-1.63-9.87-4.91-3.29v-4.94L712,466.16l-9,7.41-6.54-14-15.53-5.76,7.36-9.87-11.44-12.34,1.63-9-3.27-1.65-1.63-9.87,8.17-8.23,5.72,2.47L700.52,402l4.08-14.81,7.36-4.93,6.54-1.65,7.36-9.05,8.17-.82.82-4.12,10.62-3.28,4.91-3.3L752,348.53l11.45-4.94,4.9,4.94,5.72-4.12,9.81,3.29,5.72-6.58,12.26,4.12',
  'M151.24,139.59l11.44,7.4,2.46.82,8.17,1.64h12.26l3.27,1.65,2.45-9.87,5.72-6.58,25.34,7.4,4.09-2.47.82,7.41L242,150.28l5.72,2.46.82,6.58,4.09,3.3L264,160.15v-3.29l24.52-4.12,4.09,9,4.9.83L282,170v8.22l4.09,1.65,8.17-2.47,8.18,1.65-.82,6.58,15.53,18.1-4.9,12.34,6.53,3.29-1.63,17.27-20.44-4.11-3.27,7.4-16.35,6.58L264,230.89v-7.4l-14.72.82-3.27,9.05-9,2.47-9-2.47-4.9,11.51-5.72-2.46-9,8.23L206,247.35l-9,.82v8.23l5.73-.83,1.63,9.05h6.54L209.28,277l-17.17-3.29L179,260.51l-4.9,1.64V263l9,8.23-1.63,14-3.28-.83L175,291.77,158.6,289.3l-4.09-4.12L143.88,291,135.71,309l-3.27,6.58-6.54,1.65-8.17-4.94-4.09,4.94-13.89-.82-4.91,3.28L72,325.49,67.87,323v-6.58l-4.09-9.88L69.51,300v-8.22L64.6,286l8.17-7.41-14.71-5.75.82-3.29,7.35-2.47,10.63,3.29,10.63-8.23h4.9l10.63-7.4,4.9.82,5.72-5.75,15.53-27.15,8.17-3.29,5.73,1.64-2.46-4.93,2.46-9.05,12.26-9.87v-4.94l-2.45-18.92-5.73-.82-2.45-4.94-1.63-4.11,8.17-23.85-5.72-7.41.82-6.58,14.71.82-9.81,13.17',
  'M524.78,132.18l7.36,4.93,36.78-2.46.81,6.58-8.16,8.23,13.07,1.64L573,156l4.9,4.11h9l2.45,7.4-3.27,3.29-.81,14-.82,6.58L568.92,198l-2.46,9.87,14.72,10.69v4.94L586.9,221V226l3.27-1.65L603.25,235l9.81,8.22,1.63,12.34,6.54,3.29,9.81-1.64L644.93,263l-6.53,23,9,.82,11.44-4.11,3.27,1.64,9.81,16.46-8.17,12.34L662.1,323l4.91,23,4.08,2.47,8.18,28-5.72,3.29L667,377.32l-7.36,9.05L649,385.55l-7.35,6.58-13.08-2.47L618,397.89l-4.08-2.47-5.73,2.47-13.07,9.87L582.82,416h-18l-4.9,8.23-5.72,2.47-6.54-2.47-4.9-9.87-14.72.82-12.26-10.69.82-4.94,5.72-2.47,1.63-7.4,2.46-3.29-4.09-14,1.64-3.29-4.91-7.4V345.24l13.08-9.05-3.27-4.93,3.27-4.12-1.64-8.23L542,315.62l-3.27-27.14-12.27-15.63-5.71-7.41-3.27.82-.82-5.75h-2.45l-4.91-9,1.64-4.11L497,245.7l-6.54-3.29-12.26,1.65.82-4.12,5.72-1.64,1.63-7.41L464.3,221l-7.36-10.69,2.45-6.58-4.09-2.47L452,190.59l-8.17-3.29L461,167.55l4.08,4.11,18,1.65,21.26-4.11,4.9-4.94v-14l4.09-5.76,2.45-6.58H524l.82-5.76'
]

const districts = [
  { name: 'Imst', textTrandformMatrix: '1 0 0 1 260 316.2756' },
  { name: 'Innsbruck', textTrandformMatrix: '1 0 0 1 435.76 299' },
  { name: 'Innsbruck Land', textTrandformMatrix: '1 0 0 1 361 360.2756' },
  { name: 'Kitzbühel', textTrandformMatrix: '1 0 0 1 687 235.2756' },
  { name: 'Kufstein', textTrandformMatrix: '1 0 0 1 593 187.2756' },
  { name: 'Landeck', textTrandformMatrix: '1 0 0 1 109 372.2756' },
  { name: 'Lienz', textTrandformMatrix: '1 0 0 1 781 463.2756' },
  { name: 'Reutte', textTrandformMatrix: '1 0 0 1 178 201.2756' },
  { name: 'Schwaz', textTrandformMatrix: '1 0 0 1 560 330.2756' }
].map((values, i) => ({
  ...values,
  id: values.name.replace(' ', '_'),
  path: paths[i]
}))

export default {
  name: 'MapTyrol',
  mixins: [slideMixin],
  components: {
    draggable
  },
  data: () => ({
    districts
  }),
  methods: {
    initState: () => ({
      answers: districts.reduce((prev, { name }) => {
        prev[name.replace(' ', '_')] = false
        return prev
      }, {})
    }),
    toggleMouseOver (e) {
      const target = e ? e.target : window.event
      target.classList.toggle('district--hover')
    },
    onDragover (e) {
      e.preventDefault()
    },
    onDrop (e) {
      const value = e.dataTransfer
        .getData('text/custom')
        .replace(' ', '_')

      this.answers[e.target.id] = value
      this.toggleMouseOver(e)
    },
    setData (dataTransfer, transfereElement) {
      // "text/custome" is a custom data type so it can't be handled by the browser
      // e.g. opening the element in a new tab
      dataTransfer.setData('text/custom', transfereElement.innerHTML)
    },
    getAnswer () {
      const [ right, wrong ] = Object.entries(this.answers)
        .reduce(([right, wrong], [key, value]) => [
          right + (key === value ? 1 : 0),
          wrong + (value !== false && key !== value ? 1 : 0)
        ], [0, 0])
      return { right, wrong }
    },
    isUsed (name) {
      return Object.values(this.answers).includes(name)
    },
    isActive (name) {
      return this.answers[name] !== false
    },
    createString (id) {
      return id !== false ? id.replace('_', ' ') : ''
    }
  }
}
</script>

<style lang="scss">
.map-tyrol {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
  &__header {
    width: 48%;
    max-height: 260px;
    // overflow: hidden;
  }
  &__body {
    width: 48%;
    min-height: 200px;
    h2 {
      margin-top: 35px;
    }
    .ak-logo {
      height: 60px;
      margin-bottom: 20px;
      display: block;
    }
  }
  &__footer {
    width: 100%;
    svg {
      width: 60%;
      margin: 0 auto;
      display: block;
      margin-top: 30px;
      height: 450px;
    }
  }
  &__task {
    margin-top: 50px;
    &--headline {
      color: #559cd2;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-weight: bold;
      display: block;
    }
    &--text {
      margin: 0;
      font-weight: bold;
    }
    &--time {
      margin: 40px 0;
      display: block;
    }
  }
  .drag {
    &__area {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__item {
      width: 30%;
      padding: 5px;
      border-radius: 5px;
      background-color: #559cd2;
      color: white;
      margin-bottom: 15px;
      text-align: center;
      transition: 0.1s;
      border: 1px solid #559cd2;
      cursor: move;
      &--used {
        background-color: #1f4465;
        border-color: #1f4465;
        pointer-events: none;
      }
    }
    .sortable-ghost {
      background-color: transparent;
      border-color: lightgray;
      color: lightgray;
    }
  }
}

.district-text {
  fill: #fff;
  pointer-events: none;
  &--solution {
    fill: #000;
  }
}

.district {
  fill: #fff;
  stroke: #000;
  stroke-miterlimit: 10;
  transition: 0.1s;
  &--active {
    fill: #1f4465;
  }
  &--hover {
    fill: #559cd2;
  }
}
</style>
