import cjs from 'createjs'
/* eslint-disable */

export function createAnimation () {
var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"erstaunt.xs_atlas_", frames: [[0,1284,1482,1282],[0,0,1482,1282],[1484,0,1482,1282],[1484,1284,1482,1282]]}
];


// symbols:



(lib.avatarerstaunt01 = function() {
	this.spriteSheet = ss["erstaunt.xs_atlas_"];
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.avatarerstaunt02 = function() {
	this.spriteSheet = ss["erstaunt.xs_atlas_"];
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.avatarerstaunt03 = function() {
	this.spriteSheet = ss["erstaunt.xs_atlas_"];
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.avatarerstaunt04 = function() {
	this.spriteSheet = ss["erstaunt.xs_atlas_"];
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.avatar04 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarerstaunt04();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1482,1282);


(lib.avatar03 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarerstaunt03();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1482,1282);


(lib.avatar02 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarerstaunt02();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1482,1282);


(lib.avatar01 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.avatarerstaunt01();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1482,1282);


// stage content:
(lib.Animation = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_4
	this.instance = new lib.avatar04("synched",0);
	this.instance.parent = this;
	this.instance.setTransform(45,39,0.061,0.061,0,0,0,741.4,641.7);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(18).to({_off:false},0).to({_off:true},2).wait(28));

	// Ebene_3
	this.instance_1 = new lib.avatar03("synched",0);
	this.instance_1.parent = this;
	this.instance_1.setTransform(45,39,0.061,0.061,0,0,0,741.4,641.7);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(16).to({_off:false},0).to({_off:true},2).wait(30));

	// Ebene_2
	this.instance_2 = new lib.avatar02("synched",0);
	this.instance_2.parent = this;
	this.instance_2.setTransform(45,39,0.061,0.061,0,0,0,741.4,641.7);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(14).to({_off:false},0).to({_off:true},2).wait(4).to({_off:false},0).to({_off:true},2).wait(26));

	// Ebene_1
	this.instance_3 = new lib.avatar01("synched",0);
	this.instance_3.parent = this;
	this.instance_3.setTransform(45,39,0.061,0.061,0,0,0,741.4,641.7);

	this.timeline.addTween(cjs.Tween.get(this.instance_3).to({_off:true},14).wait(8).to({_off:false},0).wait(26));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(45,40,90,77.8);
// library properties:
lib.properties = {
	id: '4A47AEB9365C4928B11F35BF884CE015',
	width: 90,
	height: 80,
	fps: 24,
	color: "#FFFFFF",
	opacity: 0.00,
	manifest: [
		{src:"/static/images/erstaunt.xs_atlas_.png", id:"erstaunt.xs_atlas_"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	cjs.Stage.call(this, canvas);
}).prototype = p = new cjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

return {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

}
