<template>
  <div class="slide__body slide__body--score">
    <div class="score">
      <div class="score__header">
        <h1>{{ data.name }}</h1>
      </div>
      <div class="score__body">
        <div class="score__container">
          <div class="group-one group">
            <h2>Gruppe A</h2> <h2>{{ groupA.total }}</h2>
            <div class="group__stats">
              <div v-for="(team, index) in groupA.teams" :key="index" class="group__stats-row">
                <span class="group__stats-name">{{ team.name }}</span>
                <div class="group__stats-points">
                  <span>{{ team.kryptos }}</span>
                </div>
              </div>
            </div>
          </div>
          <chart :data="chartData" :options="chartOptions"></chart>
          <div class="group-two group">
            <h2>Gruppe B</h2> <h2>{{ groupB.total }}</h2>
            <div class="group__stats">
              <div v-for="(team, index) in groupB.teams" :key="index" class="group__stats-row">
                <span class="group__stats-name">{{ team.name }}</span>
                <div class="group__stats-points">
                  <span>{{ team.kryptos }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'
import Chart from './Chart.vue'

const createGroupData = group => (teams = []) => {
  const teamsInGroup = teams.filter(t => t.group === group)
  const total = teamsInGroup.reduce((total, team) => total + team.kryptos, 0)
  return {
    teams: teamsInGroup,
    total
  }
}

const createGroupDataA = createGroupData('A')
const createGroupDataB = createGroupData('B')

export default {
  name: 'Score',
  mixins: [slideMixin],
  components: {
    Chart
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true
            }
          }
        ]
      },
      attributes: {
        results: []
      }
    }
  }),
  computed: {
    groupA () {
      return createGroupDataA(this.data.attributes.results)
    },
    groupB () {
      return createGroupDataB(this.data.attributes.results)
    },
    chartData () {
      return {
        labels: ['Gruppe A', 'Gruppe B'],
        datasets: [
          {
            label: 'Ergebnisse',
            backgroundColor: ['#559cd2', '#48a4bd'],
            hoverBackgroundColor: ['#1f4465', '#287e8f'],
            data: [this.groupA.total, this.groupB.total]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
.score {
  width: 100%;
  height: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .group {
    width: 35%;
    padding-right: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__stats {
      flex-basis: 100%;
      &-row {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        &:nth-child(odd) {
          background-color: #95c0e2;
        }
      }
      &-points {
        a {
          margin-right: 40px;
          color: #1f4465;
        }
      }
      &-name {
        font-weight: bold;
      }
    }
    &.group-two {
      .group__stats-row:nth-child(odd) {
        background-color: #b9e9ea;
      }
    }
  }
}
</style>
