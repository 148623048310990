<template>
  <div class="island island--7">
    <div id="animation_container_4A47AEB9365C4928B11F35BF884CE015" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_4A47AEB9365C4928B11F35BF884CE015" style="position: absolute; background-color:rgba(255, 255, 255, 0.00); display:block; z-index: 51; right: 16.5%; top: 30.9%;"></canvas>
      <div id="dom_overlay_container_4A47AEB9365C4928B11F35BF884CE015" style="pointer-events:none; overflow:hidden;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island7',
  mounted () {
    Animation.init('4A47AEB9365C4928B11F35BF884CE015')
  }
}
</script>

<style lang="scss">
  .island--7 {
    background-image: url('/public/static/images/island7.png');
  }
</style>
