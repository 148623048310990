<template>
  <div class="slide__body slide__body--survey">
    <div class="survey">
      <div class="survey__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="survey_attributes_body">
        <div class="tabs">
          <div class="tabs__bar">
            <button v-for="(user, index) in users"
              :key="index"
              :id="user.name"
              @click="openTab(user)"
              class="tabs__tab"
              :class="{ 'tabs__tab--active' : user.isActive }"
            >
              {{ user.name }}
            </button>
          </div>
          <div class="tabs__container">
            <div class="tabs__content" v-for="(user, index) in users" :key="index" v-if="user.isActive">
              <ul>
                <li v-for="(answered, question, index) in user.questions" :key="index">
                  <label class="checkbox__container">
                    <span v-html="question"></span>
                    <input class="checkbox__field" type="checkbox" v-model="user.questions[question]">
                    <span class="checkbox__checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'Survey',
  mixins: [slideMixin],
  props: {
    metadata: {
      type: Object
    }
  },
  methods: {
    initState () {
      return {
        users: this.metadata.users.map((user, index) => ({
          name: user.name,
          isActive: index === 0,
          questions: this.data.attributes.answers.reduce((o, q) => ({
            ...o,
            [q]: false
          }), {})
        }))
      }
    },
    openTab (tab) {
      this.users.forEach(user => {
        user.isActive = false
      })
      tab.isActive = true
    },
    getAnswer () {
      return this.users.reduce((all, user) => {
        Object.entries(user.questions).forEach(([q, a]) => {
          all[q] += a ? 1 : 0
        })
        return all
      }, this.attributes.answers.reduce((o, q) => ({ ...o, [q]: 0 }), {}))
    }
  }
}
</script>

<style lang="scss">
.survey {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
  }
}
.tabs {
  width: 100%;
  margin-top: 20px;
  background-color: rgba(40, 44, 42, 0.05);
  &__bar {
    display: flex;
    justify-content: center;
    border: 1px solid transparent;
    background-color: #f0fafd;
    border-bottom: none;
  }
  &__tab {
    padding: 15px 50px;
    background-color: #94c1e3;
    font-size: 1.3rem;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 5px;
    color: white;
    flex: 1;
    transition: ease-out 0.3s;
    &:first-child {
      margin: 0;
    }
    &--active {
      background-color: #1f4465;
      color: white;
      position: relative;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white;
    ul {
      list-style: none;
      padding: 0;
      column-count: 3;
      line-height: 52px;
      column-gap: 140px;
      li {
        overflow: hidden;
      }
    }
    .checkbox {
      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding-left: 30px;
        margin: 0px;
      }
      &__checkmark {
        position: relative;
        margin: 0;
        margin-right: 9px;
      }
    }
  }
}
</style>
