<template>
  <transition name="result">
    <div v-if="solution" class="result">
      <div class="result__content">
        {{ solution.success ? 'Gut gemacht!' : 'Vielleicht klappt\'s beim nächsten Mal besser' }}
        <i class="fas fa-thumbs-up" v-if="solution && solution.success"></i>
        <i class="far fa-smile" v-else></i>
      </div>
      <div class="result__specific" v-html="slide.attributes.textSolution"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Results',
  props: ['solution', 'slide']
}
</script>

<style lang="scss">
.result {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, #f07c36 0%, #f3983f 100%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  font-size: 1.7rem;
  line-height: 40px;
  padding: 25px;
  &__content {
    color: white;
    max-width: 340px;
    width: 100%;
    margin-right: 100px;
    font-weight: bold;
  }
  &__specific {
    width: 100%;
    font-size: 1.3rem;
    line-height: 30px;
    p {
      margin: 15px 0;
    }
    &--container {
      display: flex;
      justify-content: space-between;
    }
    &--pairing {
      width: fit-content;
    }
    &--map {
      display: flex;
      justify-content: center;
      img {
        height: 400px;
      }
    }
    &--multiple-choice {
      text-align: center;
      ul {
        padding: 0;
        list-style: none;
      }
    }
  }
  &-enter {
    transform: translateY(100%);
  }
  &-enter-to {
    transform: translateY(0);
  }
  &-enter-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
}
</style>
