/**
 * The presentation wrapper can be used for slides which might
 * not be shown to the students but only in presentation mode
 * or the trainer view
 *
 * An additional flag 'showStudents' can be provided to make
 * this behavior maintainable from the backend
 */

import StudentPreviewPlaceholder from './StudentPreviewPlaceholder'

const PresentationWrapper = {
  components: {
    StudentPreviewPlaceholder
  },
  render (h) {
    // get props from the $slot child component
    const {
      context,
      attributes: { showStudents }
    } = this.$slots.default[0].context

    return context === 'student' && !showStudents ? (
      <StudentPreviewPlaceholder />
    ) : (
      this.$slots.default[0]
    )
  }
}

export const presentationMixin = {
  components: {
    PresentationWrapper
  }
}
