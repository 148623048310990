<template>
  <div class="slide__body slide__body--password" :class="'security--level-' + securityLevel">
    <div class="password">
      <div class="password__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="password__body">
        <div class="password__body--center">
          <span class="password__body--output">{{ password.length > 0 ? humanizeTimeToHack : '' }}</span>
          <div class="input input--size-large">
            <input class="input__field" type="password" placeholder="Hier Passwort eingeben" v-model="password" autocomplete="off">
          </div>
        </div>
        <div class="password__body--bottom">
          <ul>
            <li class="security-checklist" :class="{ 'security-checklist--valid' : hasNumber }">Passwort enthält Nummern</li>
            <li class="security-checklist" :class="{ 'security-checklist--valid' : hasLowerCase }">Passwort enthält Kleinbuchstaben</li>
            <li class="security-checklist" :class="{ 'security-checklist--valid' : hasUpperCase }">Passwort enthält Großbuchstaben</li>
            <li class="security-checklist" :class="{ 'security-checklist--valid' : hasSpecial }">Passwort enthält Sonderzeichen</li>
            <li class="security-checklist" :class="{ 'security-checklist--valid' : isLongerThanSeven }">Passwort ist länger als 8 Zeichen</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'
import humanizeDuration from 'humanize-duration'

export default {
  name: 'Password',
  mixins: [slideMixin],
  methods: {
    initState: () => ({
      password: ''
    })
  },
  computed: {
    hasNumber () {
      return /\d/.test(this.password)
    },
    hasLowerCase () {
      return /[a-z]/.test(this.password)
    },
    hasUpperCase () {
      return /[A-Z]/.test(this.password)
    },
    hasSpecial () {
      return /[@#€$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.password)
    },
    isLongerThanSeven () {
      return this.password.length >= 8
    },
    securityLevel () {
      return [
        this.hasNumber,
        this.hasLowerCase,
        this.hasUpperCase,
        this.hasSpecial,
        this.isLongerThanSeven
      ].filter(v => v).length
    },
    timeToHack () {
      // const keySpace = 26 + 26 + 32 + 10 // 26 upper, 26 lower, 32 special, 10 digits
      let keySpace = 1
      if (this.hasNumber) keySpace += 5
      if (this.hasLowerCase) keySpace += 13
      if (this.hasUpperCase) keySpace += 13
      if (this.hasSpecial) keySpace += 16
      const triesPerHour = 15 * Math.pow(2, 33)
      const combinations = this.isLongerThanSeven ? Math.pow(keySpace, this.password.length) : keySpace
      return combinations / triesPerHour
    },
    humanizeTimeToHack () {
      const milliseconds = Math.floor(3600 * this.timeToHack) * 1000
      return milliseconds < 1000
        ? 'sofort'
        : humanizeDuration(milliseconds, { language: 'de', largest: 1 })
    }
  }
}
</script>

<style lang="scss">
.password {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    &--output{
      font-weight: 700;
      font-size: 3rem;
      max-width: 100%;
      line-height: normal;
      margin-bottom: 15px;
    }
    &--center {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 250px;
    }
    &--bottom {
      min-height: 150px;
      flex-basis: 100%;
      width: 100%;
    }
  }
  .security-checklist {
    display: none;
    &--valid {
      display: block;
      position: relative;
      line-height: 25px;
      &:before {
        content: '\f058';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        font-weight: 100;
        font-family: 'Font Awesome\ 5 Free';
        font-size: 0.8rem;
      }
    }
  }
}

.slide__body--password.slide__body--password {
  padding-top: 30px;
  transition: 0.3s;
  .password__header h1 {
    margin-top: 0px;
  }
  &.security--level {
    &-1 {
      color: white;
      background-color: rgba(199, 71, 54, 0.95);
    }
    &-2 {
      color: white;
      background-color: rgba(227, 142, 52, 0.95);
    }
    &-3 {
    color: black;
      background-color: rgba(237, 192, 77, 0.95);
    }
    &-4 {
      color: white;
      background-color: rgba(161, 188, 81, 0.95);
    }
    &-5 {
      color: white;
      background-color: rgba(65, 143, 81, 0.95);
    }
  }
}
</style>
