<template>
  <div class="island island--3">
    <div id="animation_container_A5F185F6CC2046A396C49604F067D141" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_A5F185F6CC2046A396C49604F067D141" style="position: absolute; background-color:rgba(255, 255, 255, 0.00); display:block; z-index: 41; right: 7.8%; top: 19.9%;"></canvas>
      <div id="dom_overlay_container_A5F185F6CC2046A396C49604F067D141" style="pointer-events:none; overflow:hidden;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island3',
  mounted () {
    Animation.init('A5F185F6CC2046A396C49604F067D141')
  }
}
</script>

<style lang="scss">
  .island--3 {
    background-image: url('/public/static/images/island3.png');
  }
</style>
