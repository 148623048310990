<template>
  <div class="slide__body slide__body--hangman">
    <div class="hangman">
      <div class="hangman__header">
        <h1>{{ data.name }}</h1>
        <p v-html="attributes.text"></p>
      </div>
      <div class="hangman__body">
        <div class="hangman__body--left">
          <div class="hangman--output">
            <template v-for="(letter, index) in attributes.word">
              <span v-if="letter !== ' '"
                :key="index"
                class="hangman--output-border"
              >{{ showLetter(letter) }}</span>
              <span v-else :key="index" class="hangman--output-whitespace" ></span>
            </template>
          </div>
          <div class="hangman--input">
            <form class="input" @submit.prevent="checkGuess">
              <input class="input__field" type="text" maxlength="1" v-model="guess" :class="{ 'input--state-disabled' : (isGameOver || success) }">
              <button class="button button--color-blue" :class="{ 'button--state-disabled' : (isGameOver || success) }">
                <i class="fas fa-check"></i>
              </button>
            </form>
          </div>
          <div class="hangman__guesses-left">
            Noch <b>{{ guessesLeft }}</b> Versuche
          </div>
        </div>
        <div class="hangman__body--right">
          <div class="hangman__man">
            <img :src="`/static/images/hangman.${guessesLeft}.png`" alt="" :style="`transform: translateY(-${guessesLeft * 10}%`" :class="{ 'shake' : isGameOver }">
          </div>
          <div class="hangman__shark">
            <img src="/static/images/shark.png" alt="" :class="{ 'hangman__shark--hidden' : isGameOver }">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from './slide-mixin'

export default {
  name: 'Hangman',
  mixins: [slideMixin],
  data: () => ({
    guess: ''
  }),
  methods: {
    initState: () => ({
      guessesLeft: 10,
      lettersGuessed: []
    }),
    checkGuess () {
      if (this.isGameOver || this.guess === '') return

      const guess = this.guess.toUpperCase()

      if (
        this.attributes.word.toUpperCase().includes(guess) &&
        !this.lettersGuessed.includes(guess)
      ) {
        this.lettersGuessed.push(guess)
      } else {
        this.guessesLeft--
      }
      this.guess = ''
    },
    showLetter (letter) {
      letter = letter.toUpperCase()
      if (this.solution || this.lettersGuessed.includes(letter) || !/[a-zA-ZäöüÄÖÜß]/.test(letter)) {
        return letter
      }
    },
    getAnswer () {
      return {
        success: this.success,
        wrongTries: 10 - this.guessesLeft
      }
    }
  },
  computed: {
    isGameOver () {
      return this.guessesLeft === 0
    },
    success () {
      let word = this.attributes.word.replace(/-/g, '').replace(/ /g, '')

      return word.toUpperCase()
        .split('')
        .reduce(
          (prev, letter) => prev && this.lettersGuessed.includes(letter),
          true
        )
    }
  }
}
</script>

<style lang="scss">
.slide__body--hangman.slide__body--hangman {
  background: linear-gradient(to bottom, #ffffff, #559cd2);
}
.hangman {
  width: 100%;
  &__header {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__body {
    width: 100%;
    min-height: 200px;
    display: flex;
    &--left,
    &--right {
      flex-basis: 50%;
      width: 50%;
    }
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &--input {
    margin-top: 30px;
    .input {
      display: flex;
      &:hover {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
          0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
      }
    }
    input {
      width: 50px;
      text-transform: uppercase;
      border-color: #559cd2;
    }
    button {
      outline: 0;
      &:hover {
        box-shadow: none;
        &::before {
          content: none;
        }
      }
    }
  }
  &--output {
    width: 100%;
    display: flex;
    justify-content: center;
    &-border{
      width: 20px;
      height: 25px;
      border-bottom: 1px solid black;
      margin: 0 5px;
      font-size: 1.4rem;
      text-align: center;
    }
    &-whitespace{
      width: 20px;
      height: 25px;
      margin: 0 5px;
      font-size: 1.4rem;
      text-align: center;
    }
  }
  &__man {
    width: 100px;
    margin: 0 auto;
    height: 280px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    img {
      width: 100%;
      display: block;
      transition: 0.5s;
      animation: hoverAvatar 2s infinite linear;
    }
  }
  &__shark {
    width: 60%;
    margin: 0 auto;
    img {
      width: 80px;
      display: block;
      animation: shark 6s infinite linear;
    }
    &--hidden {
      opacity: 0;
    }
  }
  &__guesses-left {
    margin-top: 50px;
  }
  .shake {
    animation: shake 0.5s infinite linear;
  }
}

@keyframes shark {
  0% {
    transform: translate(0) scaleX(1);
  }
  35% {
    transform: translate(305px) scaleX(1);
  }
  50% {
    transform: translate(305px) scaleX(-1);
  }
  85% {
    transform: translate(0) scaleX(-1);
  }
  100% {
    transform: translate(0) scaleX(1);
  }
}

@keyframes hoverAvatar {
  0% {
    margin-bottom: 0px;
  }
  50% {
    margin-bottom: 6px;
  }
  100% {
    margin-bottom: 0px;
  }
}

</style>
