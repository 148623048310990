<template>
  <div class="team-member-input">
    <label>Name</label>
    <div class="input-button">
      <div class="input fas" :class="{ 'input--valid' : isValidUser }">
        <input class="input__field" type="text" name="name" placeholder="Name" v-model="user.name">
      </div>
      <img v-if="user.avatar" :src="`/static/images/avatare/avatar${user.avatar}.png`" class="selected-avatar">
      <button @click="selectAvatar" class="button button--color-blue">
        <span>Avatar ändern</span>
      </button>
    </div>
    <avatar-selector v-if="showAvatarSelector" @avatar-selection="id => $emit('avatar-selection', id)" />
  </div>
</template>

<script>
import { random } from 'lodash'

import AvatarSelector from './AvatarSelector.vue'

export default {
  data: () => ({
    showAvatarSelector: false
  }),
  props: {
    value: Object
  },
  components: {
    AvatarSelector
  },
  methods: {
    selectAvatar () {
      this.showAvatarSelector = true
      this.$once('avatar-selection', id => {
        this.showAvatarSelector = false
        if (id !== null) {
          this.user.avatar = id
        }
      })
    }
  },
  computed: {
    isValidUser () {
      return this.user.name >= 3
    },
    user () {
      return this.value
    }
  },
  watch: {
    user () {
      this.$emit('input', this.user)
    },
    'user.name' (newValue, oldValue) {
      if (oldValue === '' && this.user.avatar === null) {
        this.user.avatar = random(1, 30)
      }
    }
  }
}
</script>

<style>

</style>
