<template>
  <div class="island island--5">
    <div id="animation_container_B4F2096402134CD389629D0A0154CFC8" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_B4F2096402134CD389629D0A0154CFC8" style="position: absolute; background-color:rgba(255, 255, 255, 0.00); display:block; z-index: 51; right: 17.2%; top: 31.2%;"></canvas>
      <div id="dom_overlay_container_B4F2096402134CD389629D0A0154CFC8" style="pointer-events:none; overflow:hidden;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island5',
  mounted () {
    Animation.init('B4F2096402134CD389629D0A0154CFC8')
  }
}
</script>

<style lang="scss">
  .island--5 {
    background-image: url('/public/static/images/island5.png');
  }
</style>
