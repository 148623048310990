<template>
  <div class="slide__body slide__body--student-preview-placeholder">
    <div>
      <img src="/static/images/AK-TIROL.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentPreviewPlaceholder'
}
</script>

<style lang="scss">
  .slide__body--student-preview-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 500px;
      img {
        width: 100%;
      }
    }
  }
</style>
