import cjs from 'createjs'
/* eslint-disable */

export function createAnimation () {
var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"licht.xs_atlas_", frames: [[0,0,2560,1718],[0,1720,2560,720]]}
];

// symbols:

(lib.akinsel02animation01 = function() {
	this.spriteSheet = ss["licht.xs_atlas_"];
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.akinsel02animation02 = function() {
	this.spriteSheet = ss["licht.xs_atlas_"];
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.on = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.akinsel02animation02();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,2560,720);


(lib.fix = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.akinsel02animation01();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,2560,1718);


// stage content:
(lib.Animation = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Ebene_1
	this.instance = new lib.on("synched",0);
	this.instance.parent = this;
	this.instance.setTransform(119,79.9,0.093,0.093,0,0,0,1280.2,859);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(73).to({startPosition:0},0).to({alpha:0},3).to({alpha:1},3).to({alpha:0},3).to({alpha:1},3).to({alpha:0},2).to({startPosition:0},40).to({alpha:1},5).wait(1));

	// Ebene_3
	this.instance_1 = new lib.fix("synched",0);
	this.instance_1.parent = this;
	this.instance_1.setTransform(119,79.9,0.093,0.093,0,0,0,1280.2,859);

	this.instance_2 = new lib.akinsel02animation01();
	this.instance_2.parent = this;
	this.instance_2.setTransform(0,0,0.093,0.093);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_1}]}).to({state:[{t:this.instance_2}]},132).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(119,80,238,159.7);
// library properties:
lib.properties = {
	id: 'A5F185F6CC2046A396C49604F067D141',
	width: 238,
	height: 160,
	fps: 24,
	color: "#FFFFFF",
	opacity: 0.00,
	manifest: [
		{src:"/static/images/licht.xs_atlas_.png", id:"licht.xs_atlas_"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	cjs.Stage.call(this, canvas);
}).prototype = p = new cjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

return {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};
}
