<template>
  <div class="slide__body slide__body--login">
    <div class="login">
      <div class="login__header">
        <h2>Loggt euch bitte ein:</h2>
      </div>
      <div class="login__body">
        <div class="login__body--section">
          <h4>Teamname:</h4>
          <label>Wählt einen Teamnamen:</label>
          <div class="input fas" :class="{ 'input--valid' : isValidTeamName }">
            <input class="input__field" type="text" placeholder="Teamname" v-model="teamName">
          </div>
          <p>Beispiel: die Musketiere, Powergirls, Traumfänger etc.</p>
        </div>
        <div class="login__body--section">
          <h4>Mitspieler im Team:</h4>
          <label>Tragt eure Namen ein und sucht euch jeweils einen Avatar aus:</label>
          <div class="form-container">
            <div class="input-container">
              <TeamMemberInput v-for="(user, index) in users"
                :key="index"
                v-model="users[index]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideMixin } from '../slide-mixin'
import TeamMemberInput from './TeamMemberInput.vue'

export default {
  name: 'Login',
  components: {
    TeamMemberInput
  },
  mixins: [slideMixin],
  methods: {
    initState: () => ({
      teamName: '',
      users: [
        { name: '', avatar: null },
        { name: '', avatar: null },
        { name: '', avatar: null }
      ]
    }),
    emitTeamChange () {
      this.$parent.$parent.$emit('team-update', this.team)
    }
  },
  computed: {
    isValidTeamName () {
      return this.teamName.length >= 3
    },
    team () {
      // remove vue getters/setters
      return JSON.parse(JSON.stringify({
        name: this.teamName,
        members: this.users
      }))
    }
  },
  watch: {
    team () {
      this.emitTeamChange()
    }
  }
}
</script>

<style lang="scss">
$darkblue: #1f4465;
$blue: #559cd2;
$lightblue: #95c0e2;

.login {
  width: 100%;
  &__header {
    h2 {
      margin-top: 0;
    }
  }
  &__body {
    justify-content: space-between;
    &--section {
      margin-top: 40px;
    }
    h4 {
      display: block;
      font-weight: bold;
      color: $blue;
      margin-bottom: 5px;
    }
    label {
      font-weight: bold;
      width: 100%;
      flex-basis: 100%;
      margin-bottom: 3px;
      display: block;
    }
    .input-container {
      label {
        margin-top: 15px;
      }
    }
    p {
      font-size: 1rem;
    }
    .input-button {
      align-items: center;
      height: 40px;
      input {
        margin-right: 10px;
      }
      button {
        margin-left: 10px;
      }
    }
  }
  &__footer {
    width: 100%;
  }
  .selected-avatar {
    height: 60px;
    width: auto;
    display: block;
    margin-left: 10px;
  }
  .fas input {
    padding-right: 35px;
  }
}

.form-container {
  display: flex;
}
.input-container {
  flex: 1;
}
.button-container {
  display: flex;
  align-items: flex-end;
}
</style>
