<template>
  <div class="slide-pause">
    Kurze Pause
  </div>
</template>

<script>
export default {
  name: 'SlidePaused'
}
</script>

<style lang="scss">
  .slide-pause {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    font-size: 2.7rem;
  }
</style>
