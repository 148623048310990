export const createSubscriber = ({ socket }) => property => {
  const cbs = []
  socket.on(`${property}-update`, data => {
    cbs.forEach(cb => cb(data))
  })

  socket.emit('subscribe', property)

  const chain = {
    onUpdate: cb => {
      cbs.push(cb)
      return chain
    },
    unSubscribe () {
      socket.off(`${property}-update`)
    }
  }

  return chain
}
