import cjs from 'createjs'

/* eslint-disable */
export function createAnimation () {
  var p; // shortcut to reference prototypes
  var lib = {}; var ss = {}; var img = {};
  lib.ssMetadata = [
    { name: "auslachen.xs_atlas_", frames: [[0, 0, 1800, 1282], [0, 1284, 1800, 1282]] }
  ];

  (lib.avatarauslachen01 = function () {
    this.spriteSheet = ss["auslachen.xs_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.avatarauslachen02 = function () {
    this.spriteSheet = ss["auslachen.xs_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.auslachen02 = function (mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Ebene_1
    this.instance = new lib.avatarauslachen02();
    this.instance.parent = this;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 1800, 1282);


  (lib.auslachen01 = function (mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Ebene_1
    this.instance = new lib.avatarauslachen01();
    this.instance.parent = this;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 1800, 1282);


  // stage content:
  (lib.Animation = function (mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Ebene_2
    this.instance = new lib.auslachen02("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(47.5, 33.8, 0.053, 0.053, 0, 0, 0, 900.2, 640.6);
    this.instance._off = true;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(4).to({ _off: false }, 0).wait(4));

    // Ebene_1
    this.instance_1 = new lib.auslachen01("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(47.5, 33.8, 0.053, 0.053, 0, 0, 0, 900.2, 640.6);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).to({ _off: true }, 4).wait(4));

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(47.5, 34, 95, 67.7);
  // library properties:
  lib.properties = {
    id: 'B4F2096402134CD389629D0A0154CFC8',
    width: 95,
    height: 68,
    fps: 24,
    color: "#FFFFFF",
    opacity: 0.00,
    manifest: [
      { src: "/static/images/auslachen.xs_atlas_.png", id: "auslachen.xs_atlas_" }
    ],
    preloads: []
  };

  // bootstrap callback support:

  (lib.Stage = function (canvas) {
    cjs.Stage.call(this, canvas);
  }).prototype = p = new cjs.Stage();

  p.setAutoPlay = function (autoPlay) {
    this.tickEnabled = autoPlay;
  }
  p.play = function () { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
  p.stop = function (ms) { if (ms) this.seek(ms); this.tickEnabled = false; }
  p.seek = function (ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
  p.getDuration = function () { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

  p.getTimelinePosition = function () { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

  return {
    getStage: function() { return exportRoot.getStage(); },
    getLibrary: function() { return lib; },
    getSpriteSheet: function() { return ss; },
    getImages: function() { return img; }
  }
}
