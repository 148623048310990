<template>
  <div class="island island--1">
    <div id="animation_container_2BC1D9F0641643E299E2501102553595" style="background-color:rgba(255, 255, 255, 0.00);">
      <canvas id="canvas_2BC1D9F0641643E299E2501102553595" style="position: absolute; background-color:rgba(255, 255, 255, 0.00); display:block; z-index: 51; left: 10%; top: 21.5%;"></canvas>
      <div id="dom_overlay_container_2BC1D9F0641643E299E2501102553595" style="pointer-events:none; overflow:hidden;"></div>
    </div>
  </div>
</template>

<script>
import Animation from '../animations'

export default {
  name: 'island1',
  mounted () {
    Animation.init('2BC1D9F0641643E299E2501102553595')
  }
}
</script>

<style lang="scss">
  .island--1 {
    background-image: url('/public/static/images/island1.png');
  }
</style>
